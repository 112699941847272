import { handleError } from "../../helpers/handleError";
import axios from "axios";

export const LOAD_COMMENTS = "LOAD_COMMENTS";
export const LOAD_COMMENTS_SUCCESS = "LOAD_COMMENTS_SUCCESS";
export const LOAD_COMMENTS_FAILURE = "LOAD_COMMENTS_FAILURE";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

const baseUrl = process.env.REACT_APP_API_URL;

export const addComment = (contractId, text, author) => {
  return dispatch => {
    dispatch({ type: ADD_COMMENT });
    axios
      .post(`${baseUrl}api/contracts/${contractId}/comments`, { text })
      .then(() => {
        dispatch(addCommentSuccess(text, author));
      })
      .catch(error => dispatch(addCommentFailure(error)));
  };
};

export const addCommentSuccess = (text, author) => {
  return {
    type: ADD_COMMENT_SUCCESS,
    text,
    author,
  };
};

export const addCommentFailure = error => {
  handleError(error);
  return {
    type: ADD_COMMENT_FAILURE,
    error: error,
  };
};

export const loadComments = contractId => {
  return dispatch => {
    dispatch({ type: LOAD_COMMENTS });
    axios
      .get(`${baseUrl}api/contracts/${contractId}/comments`)
      .then(response => {
        dispatch(loadCommentsSuccess(response.data.count, response.data.data));
      })
      .catch(error => dispatch(loadCommentsFailure(error)));
  };
};

export const loadCommentsSuccess = (count, comments) => {
  return {
    type: LOAD_COMMENTS_SUCCESS,
    count,
    comments,
  };
};

export const loadCommentsFailure = error => {
  handleError(error);
  return {
    type: LOAD_COMMENTS_FAILURE,
    error: error,
  };
};
