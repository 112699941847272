import * as actionTypes from "../actions/contractors.actions";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  loading: false,
  contractors: null,
  count: null,
  page: 0,
  searchString: null,
};

const contractorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_FILTER: {
      return {
        ...cloneDeep(state),
        loading: true,
        contractors: [],
        count: null,
        page: 0,
        searchString: action.searchString,
      };
    }
    case actionTypes.LOAD_CONTRACTORS: {
      return {
        ...cloneDeep(state),
        loading: true,
      };
    }
    case actionTypes.LOAD_CONTRACTORS_SUCCESS: {
      const newcontractors = state.contractors
        ? cloneDeep(state.contractors)
        : [];
      newcontractors.push(...action.contractors);
      return {
        ...cloneDeep(state),
        count: action.count,
        contractors: newcontractors,
        page: action.page,
        loading: false,
      };
    }
    case actionTypes.CHANGE_PAGE: {
      return {
        ...cloneDeep(state),
        page: action.page,
      };
    }
    case actionTypes.UPDATE_CONTRACTORS_SUCCESS: {
      let newcontractors = cloneDeep(state.contractors);
      const index = newcontractors.findIndex(
        contractor => contractor._id === action.contractorId,
      );
      newcontractors[index] = {
        ...newcontractors[index],
        ...action.contractor,
      };
      return {
        ...cloneDeep(state),
        contractors: newcontractors,
      };
    }
    case actionTypes.ADD_CONTRACTORS_SUCCESS: {
      let newcontractors = cloneDeep(state.contractors);
      newcontractors.push(action.contractor);
      return {
        ...cloneDeep(state),
        contractors: newcontractors,
      };
    }
    default:
      return state;
  }
};

export default contractorsReducer;
