import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import { useStyles } from "../../helpers/stepper-styles";
import TemplateStep from "../../components/AddAgreementSteps/TemplateStep";
import UsersStep from "../../components/AddAgreementSteps/UsersStep";
import MetadataStep from "../../components/AddAgreementSteps/MetadataStep";
import AttachmentStep from "../../components/AddAgreementSteps/AttachmentStep";
import { useSelector, useDispatch } from "react-redux";
import {
  addAgreement,
  confirmDocument,
} from "../../store/actions/agreements.actions";
import { history } from "../../helpers/history";

function getSteps() {
  return [
    "Wybierz dział i szablon",
    "Potwierdź dostęp użytkowników",
    "Uzupełnij dane dokumentu",
    "Dodaj załączniki",
  ];
}

const AddAgreementPage = () => {
  const dispatch = useDispatch();
  const {
    newAgreement,
    agreement,
    templates,
    templateId,
    newDocumentId,
  } = useSelector(state => ({
    newAgreement: state.newAgreement,
    agreement: state.agreement.agreement,
    templates: state.templates.templates,
    templateId: state.newAgreement.templateId,
    newDocumentId: state.newDocument.documentId,
  }));

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <TemplateStep next={handleNext}></TemplateStep>;
      case 1:
        return <UsersStep back={handleBack} next={handleNext}></UsersStep>;
      case 2:
        return (
          <MetadataStep
            back={handleBack}
            next={(metadata, contractor) => handleAddDoc(metadata, contractor)}
            templates={templates}
            templateId={templateId}
          ></MetadataStep>
        );
      case 3:
        return (
          <AttachmentStep
            back={handleBack}
            add={handleConfirm}
          ></AttachmentStep>
        );
      default:
        return "Unknown step";
    }
  };

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleAddDoc = (metadata, contractor) => {
    let assignedUsers = newAgreement.users.map(user => ({
      isKeeper: false,
      user: user._id,
    }));

    if(newAgreement.keeper) {
      const index =  assignedUsers.findIndex(
        user => user.user === newAgreement.keeper._id,
      );
  
      if (index !== -1) {
        assignedUsers[index].isKeeper = true;
      } else {
        assignedUsers.push({ isKeeper: true, user: newAgreement.keeper._id });
      }
    }

    const agreement = {
      contractorId: contractor._id,
      templateId: newAgreement.templateId,
      assignedUsers: assignedUsers,
    };

    const { contract_start_date, contract_end_date, type, ...rest } = metadata;

    const document = {
      type: type,
      metadata: rest,
      startDate: contract_start_date,
      endDate: contract_end_date,
    };

    dispatch(addAgreement(agreement, document));
    handleNext();
  };

  const handleConfirm = () => {
    if (agreement && newDocumentId) {
      dispatch(confirmDocument(newDocumentId, agreement._id));
      history.push("/documents");
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>{getStepContent(index)}</StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default AddAgreementPage;
