import React from "react";
import SVG from "react-inlinesvg";
import "./IconButton.scss";

const IconButton = props => {
  const { icon, color, btnClick, disabled, ...rest } = props;

  const handleBtnClick = () => {
    if (props.btnClick && !disabled) {
      props.btnClick();
    }
  };

  return (
    <div
      onClick={handleBtnClick}
      className={disabled ? "disabled icon-button" : "icon-button"}
      {...rest}
    >
      <SVG className="icon" src={`/icons/${props.icon}.svg`}></SVG>
    </div>
  );
};

export default IconButton;
