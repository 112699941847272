const otherContractParty = [
  { id: "nip", label: "NIP", type: "nip", required: true },
  { id: "name", label: "Nazwa", type: "string", required: true },
];

const retro = [
  { id: "name", label: "Nazwa", type: "string", required: true },
  { id: "trade_level", label: "Poziom obrotu", type: "string", required: true },
  { id: "value", label: "Wartość", type: "string", required: true },
];

const charge = [
  { id: "reason", label: "Za co", type: "string", required: true },
  { id: "value", label: "Kwota", type: "string", required: true },
];

const contactPeson = [
  { id: "first_name", label: "Imię", type: "string", required: true },
  { id: "last_name", label: "Nazwisko", type: "string", required: true },
  { id: "phone_number", label: "Numer telefonu", type: "phone_number" },
  { id: "e-mail", label: "E-mail", type: "email" },
];

export const typeOptions = {
  CONTRACT: "Umowa",
  CONTRACT_PROPOSAL: "Propozycja umowy",
  ADDENDUM: "Aneks",
  OTHER: "Inny",
};

export const templateFields = [
  {
    id: "type",
    label: "Typ",
    type: "select",
    options: Object.entries(typeOptions).map(([key, value]) => ({
      label: value,
      value: key,
    })),
    required: true,
    star: true,
  },
  {
    id: "contract_start_date",
    label: "Data początku umowy",
    type: "date",
    required: true,
    star: true,
  },
  {
    id: "contract_end_date",
    label: "Data końca umowy",
    type: "date",
    required: true,
    star: true,
  },
  {
    id: "contract_subject",
    label: "Przedmiot umowy",
    type: "string",
    star: true,
  },
  {
    id: "maturity",
    label: "Termin płatności (dni)",
    type: "number",
    star: true,
  },
  { id: "contract_value", label: "Wartość umowy", type: "string", star: true },
  {
    id: "contract_execution_term",
    label: "Termin realizacji umowy",
    type: "date",
    star: true,
  },
  {
    id: "information_for_trade",
    label: "Informacja dla handlu",
    type: "select",
    star: true,
    options: [
      { label: "RT", value: "RT" },
      { label: "RN", value: "RN" },
      { label: "EXPORT", value: "EXPORT" },
      { label: "INNE", value: "INNE" },
    ],
  },
  {
    id: "other_contract_parties",
    label: "Inne strony umowy",
    type: "list",
    groupFields: otherContractParty,
  },

  { id: "retro", label: "Retro", type: "list", groupFields: retro },
  { id: "charge", label: "Opłaty", type: "list", groupFields: charge },
  { id: "penalties", label: "Kary", type: "list", groupFields: charge },

  {
    id: "contact_person",
    label: "Osoba kontaktowa",
    type: "list",
    groupFields: contactPeson,
  },
];
