/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "../ui/IconButton/IconButton";
import CustomSelect from "../../components/ui/CustomSelect/CustomSelect";
import {
  ClearTableContainer,
  StyledTableCell,
  StyledTableHead,
} from "../../helpers/table-styles";
import {
  loadUsers,
  changePage,
  selectDepartment,
} from "../../store/actions/users.actions";
import { useDispatch, useSelector } from "react-redux";

const AddUserToListModal = props => {
  const { open, close, addUser, usersOnListIds, headerText } = props;

  const [rowsPerPage] = React.useState(5);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      selectDepartment({
        ...filters,
        department: null,
      }),
      setSelectedDepartment("all"),
    );
    close();
  };

  const { loading, users, count, page, filters, departments } = useSelector(
    state => ({
      loading: state.users.loading,
      users: state.users.users,
      count: state.users.count,
      page: state.users.page,
      filters: state.users.filters,
      departments: state.departments.departments,
    }),
  );

  const [departmentOptions] = useState(
    departments
      ? [
          { value: "all", label: "Wszystkie" },
          ...departments.map(department => ({
            value: department._id,
            label: department.name,
          })),
        ]
      : [{ value: "all", label: "Wszystkie" }],
  );

  const [selectedDepartment, setSelectedDepartment] = useState(
    departmentOptions[0].value,
  );

  useEffect(() => {
    dispatch(
      selectDepartment({
        ...filters,
        department: null,
      }),
    );
  }, []);

  const selectDepartmentOption = event => {
    const value = event.target.value;

    dispatch(
      selectDepartment({
        ...filters,
        department: value !== "all" ? value : null,
      }),
      setSelectedDepartment(value),
    );
  };

  const handleChangePage = (event, newPage) => {
    if (newPage * rowsPerPage >= users.length) {
      dispatch(loadUsers(newPage, filters, rowsPerPage));
    } else {
      dispatch(changePage(newPage));
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal modal-m">
        <div className="modal-title">
          <p>{headerText}</p>
        </div>
        <div className="modal-content">
          <CustomSelect
            label="Dział"
            className="mb-4"
            changeOption={selectDepartmentOption}
            selectedOption={selectedDepartment}
            options={departmentOptions}
            theme="dark"
          ></CustomSelect>
          {count > 0 && (
            <React.Fragment>
              <ClearTableContainer>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>Imię i nazwisko</StyledTableCell>
                      <StyledTableCell>E-mail</StyledTableCell>
                      <StyledTableCell align="right">
                        Dodaj do działu
                      </StyledTableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {users
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map(row => (
                        <TableRow key={row._id}>
                          <StyledTableCell>
                            {row.firstName + " " + row.lastName}
                          </StyledTableCell>
                          <StyledTableCell>{row.emailAddress}</StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              disabled={usersOnListIds.includes(row._id)}
                              btnClick={() => addUser(row)}
                              style={{ marginLeft: "auto" }}
                              icon="plus"
                            ></IconButton>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  count={count}
                  page={page}
                  nextIconButtonProps={{
                    disabled:
                      loading || page + 1 >= Math.ceil(count / rowsPerPage),
                  }}
                  backIconButtonProps={{ disabled: loading || page === 0 }}
                  onChangePage={handleChangePage}
                />
              </ClearTableContainer>
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddUserToListModal;
