import React, { useState } from "react";
import { templateFields } from "../../helpers/template-fields-list";
import CustomInput, { StyledInput } from "../ui/CustomInput/CustomInput";
import IconButton from "../ui/IconButton/IconButton";
import CustomSelect from "../ui/CustomSelect/CustomSelect";
import {
  ClearTableContainer,
  StyledTableCell,
  SimpleTableHead,
} from "../../helpers/table-styles";
import CustomCheckbox from "../ui/CustomCheckbox/CustomCheckbox";
import { DatePicker } from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import cloneDeep from "lodash/cloneDeep";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import SelectContractorDialog from "../../components/SelectContractorDialog/SelectContractorDialog";
import EditContractorDialog from "../../components/EditContractorDialog/EditContractorDialog";
import { addContractor } from "../../store/actions/contractors.actions";

const MetadataStep = props => {
  const {
    back,
    next,
    templates,
    templateId,
    initialMetadata,
    initialContractor,
  } = props;
  const [contractor, setContractor] = useState(initialContractor);
  const [selectContractorModalOpen, setSelectContractorModalOpen] = useState(
    false,
  );
  const getTemplateField = fieldName => {
    return templateFields.find(templateField => templateField.id === fieldName);
  };

  const requiredFields = initialMetadata
    ? Object.keys(initialMetadata)
    : templates.find(template => template._id === templateId).requiredFields;

  const setInitialErrors = templateFields => {
    const errors = {};

    templateFields.forEach(templateField => {
      switch (templateField.type) {
        case "string":
        case "number":
        case "email":
        case "phone_number":
        case "select":
        case "date":
          Object.assign(errors, { [templateField.id]: "" });
          break;
        case "group":
        case "list":
          {
            const newGroup = {};
            templateField.groupFields.forEach(group =>
              Object.assign(newGroup, { [group.id]: "" }),
            );
            Object.assign(errors, { [templateField.id]: newGroup });
          }
          break;
        default:
          break;
      }
    });

    return errors;
  };

  const setInitialDataLists = templateFields => {
    const lists = {};

    if (initialMetadata) {
      Object.entries(initialMetadata).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          Object.assign(lists, { [key]: value });
        }
      });
    } else {
      templateFields.forEach(templateField => {
        switch (templateField.type) {
          case "list":
            Object.assign(lists, { [`${templateField.id}`]: [] });
            break;
          default:
            break;
        }
      });
    }

    return lists;
  };

  const setFormInputValues = templateFields => {
    const metadata = {};

    templateFields.forEach(templateField => {
      switch (templateField.type) {
        case "string":
        case "nip":
        case "number":
        case "email":
        case "phone_number": {
          Object.assign(metadata, {
            [templateField.id]:
              initialMetadata && initialMetadata[templateField.id]
                ? initialMetadata[templateField.id]
                : "",
          });
          break;
        }
        case "select": {
          Object.assign(metadata, {
            [templateField.id]:
              initialMetadata && initialMetadata[templateField.id]
                ? initialMetadata[templateField.id]
                : templateField.options[0].value,
          });
          break;
        }
        case "date": {
          Object.assign(metadata, {
            [templateField.id]:
              initialMetadata && initialMetadata[templateField.id]
                ? new Date(initialMetadata[templateField.id])
                : new Date(),
          });
          break;
        }
        case "group": {
          const newGroup = {};
          templateField.groupFields.forEach(group =>
            Object.assign(newGroup, {
              [group.id]:
                initialMetadata && initialMetadata[templateField.id]
                  ? initialMetadata[templateField.id][group.id]
                  : "",
            }),
          );
          Object.assign(metadata, { [templateField.id]: newGroup });
          break;
        }
        case "list": {
          const newGroup = {};
          templateField.groupFields.forEach(group =>
            Object.assign(newGroup, { [group.id]: "" }),
          );
          Object.assign(metadata, { [templateField.id]: newGroup });
          break;
        }
        default:
          break;
      }
    });

    return metadata;
  };

  const setInitialState = () => {
    let templateFields = requiredFields.map(requiredField =>
      getTemplateField(requiredField),
    );

    templateFields = templateFields.filter(templateFields => !!templateFields);
    const metadata = setFormInputValues(templateFields);
    Object.assign(metadata, { errors: setInitialErrors(templateFields) });
    Object.assign(metadata, { lists: setInitialDataLists(templateFields) });

    return metadata;
  };

  const [metadata, setMetadata] = useState(setInitialState());
  const [error, setError] = useState("");

  const addListElement = id => {
    const newList = cloneDeep(metadata.lists[`${id}`]);
    newList.push(metadata[`${id}`]);

    const clearedFields = {};
    Object.keys(metadata[`${id}`]).map(key =>
      Object.assign(clearedFields, { [key]: "" }),
    );
    setMetadata({
      ...cloneDeep(metadata),
      [`${id}`]: clearedFields,
      lists: {
        ...cloneDeep(metadata.lists),
        [`${id}`]: newList,
      },
    });
  };

  const listFieldsValid = templateFields => {
    let valid = true;
    templateFields.groupFields.forEach((field, index) => {
      if (
        field.required &&
        Object.values(metadata[`${templateFields.id}`])[index] === ""
      ) {
        valid = false;
      }
      if (
        Object.values(metadata.errors[`${templateFields.id}`])[index !== ""]
      ) {
        valid = false;
      }
    });
    return valid;
  };

  const removeListElement = (id, index) => {
    const newList = cloneDeep(metadata.lists[`${id}`]);
    newList.splice(index, 1);
    setMetadata({
      ...cloneDeep(metadata),
      lists: {
        ...cloneDeep(metadata.lists),
        [`${id}`]: newList,
      },
    });
  };

  const isStringValid = (type, value) => {
    switch (type) {
      case "number": {
        const regex = /^[\\.\\,0-9\b]+$/;
        return (value === "") | regex.test(value);
      }
      case "nip": {
        const regex = /^[0-9\b]+$/;
        return (value === "") | regex.test(value);
      }
      case "phone_number": {
        const regex = /^[\s\\+0-9\b]+$/;
        return (value === "") | regex.test(value);
      }
      default:
        return true;
    }
  };

  const handleInputChange = (event, type, parentId) => {
    const value = event.target.value;
    const name = event.target.name;

    const metadataCopy = cloneDeep(metadata);

    if (isStringValid(type, value)) {
      parentId
        ? setMetadata({
            ...metadataCopy,
            [parentId]: {
              ...metadataCopy[parentId],
              [name]: value,
            },
            errors: {
              ...metadataCopy.errors,
              [parentId]: {
                ...metadataCopy.errors[parentId],
                [name]: getError(type, value),
              },
            },
          })
        : setMetadata({
            ...metadataCopy,
            [name]: value,
            errors: {
              ...metadataCopy.errors,
              [name]: getError(type, value),
            },
          });
    }
  };

  const handleDateChange = (date, name) => {
    //to do nesting
    setMetadata({
      ...cloneDeep(metadata),
      [name]: date,
    });
  };

  const handleSelectChange = (event, name) => {
    //to do nesting
    setMetadata({
      ...cloneDeep(metadata),
      [name]: event.target.value,
    });
  };

  const getError = (type, value) => {
    switch (type) {
      case "string":
      case "number": {
        if (value === "") {
          return "Pole obowiązkowe";
        }
        return "";
      }
      case "phone_number": {
        const regexAreaCode = /^(\+|00)(\d[0-9]{0,2})(\s|\S)(\d[0-9]{8,9})$/;
        const regexNoAreaCode = /^(\d[0-9]{8,9})$/;
        if (!regexAreaCode.test(value) && !regexNoAreaCode.test(value)) {
          return "Nieprawidłowy numer";
        }
        return "";
      }
      case "nip": {
        const regex = /^(\d[0-9]{9})$/;
        if (!(value === "") && !regex.test(value)) {
          return "Numer NIP ma 10 cyfr";
        }
        return "";
      }
      case "email": {
        const regex = /^\S+@\S+\.\S+$/;
        if (!(value === "") && !regex.test(value)) {
          return "Nieprawidłowy adres e-mail";
        }
        return "";
      }
      default:
        return "";
    }
  };

  const hadnleSelectContractor = contractor => {
    setContractor(contractor);
    setSelectContractorModalOpen(false);
  };

  const setErrors = () => {
    const newErrors = cloneDeep(metadata.errors);
    let isError = false;

    requiredFields.forEach(requiredField => {
      const templateField = getTemplateField(requiredField);
      if (templateField) {
        switch (templateField.type) {
          case "string":
          case "nip":
          case "number": {
            Object.assign(newErrors, {
              [templateField.id]:
                metadata[templateField.id] === ""
                  ? "Pole obowiązkowe"
                  : getError(templateField.type, metadata[templateField.id]),
            });

            if (newErrors[templateField.id] !== "") {
              isError = true;
            }
            break;
          }
          case "group": {
            const newGroup = {};
            templateField.groupFields.forEach(group => {
              Object.assign(newGroup, {
                [group.id]:
                  metadata[templateField.id][group.id] === ""
                    ? "Pole obowiązkowe"
                    : getError(
                        group.type,
                        metadata[templateField.id][group.id],
                      ),
              });

              if (newErrors[templateField.id][group.id] !== "") {
                isError = true;
              }
            });
            Object.assign(newErrors, { [templateField.id]: newGroup });
            break;
          }
          default:
            break;
        }
      }
    });

    setMetadata({
      ...cloneDeep(metadata),
      errors: newErrors,
    });

    return isError;
  };
  const [openNew, setOpenNew] = useState(false);
  const dispatch = useDispatch();

  const handleAdd = contractor => {
    dispatch(addContractor(contractor));
    setOpenNew(false);
    setSelectContractorModalOpen(true);
  };

  const getFormInput = (templateField, parentId = null) => {
    switch (templateField.type) {
      case "string":
      case "nip":
      case "number":
      case "email":
      case "phone_number":
        return (
          <CustomInput
            className="mr-4 mb-3"
            key={templateField.id}
            star={templateField.star}
            label={templateField.label}
            error={
              parentId
                ? metadata.errors[parentId][templateField.id] !== ""
                : metadata.errors[templateField.id] !== ""
            }
            helperText={
              parentId
                ? metadata.errors[parentId][templateField.id]
                : metadata.errors[templateField.id]
            }
          >
            <StyledInput
              name={templateField.id}
              value={
                parentId
                  ? metadata[parentId][templateField.id]
                  : metadata[templateField.id]
              }
              onChange={event =>
                handleInputChange(event, templateField.type, parentId)
              }
            />
          </CustomInput>
        );
      case "select": {
        return (
          <CustomSelect
            star={templateField.star}
            label={templateField.label}
            style={{ marginRight: "1.5rem", marginBottom: "1rem" }}
            key={templateField.id}
            changeOption={event => handleSelectChange(event, templateField.id)}
            selectedOption={metadata[templateField.id]}
            options={templateField.options}
          ></CustomSelect>
        );
      }
      case "date":
        const date = parentId
          ? metadata[parentId][templateField.id]
          : metadata[templateField.id];
        return (
          <div key={templateField.id} className="flex-row a-end">
            {!!date && (
              <DatePicker
                name={templateField.id}
                error={
                  parentId
                    ? metadata.errors[parentId][templateField.id] !== ""
                    : metadata.errors[templateField.id] !== ""
                }
                helperText={
                  parentId
                    ? metadata.errors[parentId][templateField.id]
                    : metadata.errors[templateField.id]
                }
                className="mr-4 mb-3"
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                label={templateField.label}
                value={date}
                onChange={date => handleDateChange(date._d, templateField.id)}
              />
            )}
            {templateField.id === "contract_end_date" && (
              <CustomCheckbox
                className="checkbox"
                style={{ marginBottom: "calc(1rem - 9px)", width: "100%" }}
                checked={!date}
                label={"Umowa na czas nieoreśliny"}
                onChange={event => {
                  handleDateChange(
                    event.target.checked ? null : new Date(),
                    templateField.id,
                  );
                }}
              ></CustomCheckbox>
            )}
          </div>
        );
      case "group":
        return (
          <div className="group my-4" key={templateField.id}>
            <p className="font-s bold mb-3">{templateField.label}</p>
            {templateField.groupFields.map(groupField =>
              getFormInput(groupField, templateField.id),
            )}
          </div>
        );
      case "list":
        return (
          <div className="group my-4" key={templateField.id}>
            <p className="font-s bold mb-3">{templateField.label}</p>
            <div className="flex-row flex-wrap">
              <div className="flex-row">
                {templateField.groupFields.map(groupField =>
                  getFormInput(groupField, templateField.id),
                )}
                <IconButton
                  disabled={!listFieldsValid(templateField)}
                  style={{ height: "39px", width: "39px", marginTop: "24px" }}
                  btnClick={() => addListElement(templateField.id)}
                  icon="plus"
                ></IconButton>
              </div>
              {metadata.lists[`${templateField.id}`].length > 0 && (
                <ClearTableContainer>
                  <Table
                    size="small"
                    style={{ marginLeft: "-15px", maxWidth: "1000px" }}
                  >
                    <SimpleTableHead>
                      <TableRow>
                        {templateField.groupFields.map((field, index) => (
                          <StyledTableCell key={index}>
                            {field.label}
                          </StyledTableCell>
                        ))}
                        <StyledTableCell align="right">Usuń</StyledTableCell>
                      </TableRow>
                    </SimpleTableHead>
                    <TableBody>
                      {metadata.lists[`${templateField.id}`].map(
                        (row, index) => (
                          <TableRow key={index}>
                            {Object.values(row).map(cell => (
                              <StyledTableCell component="th" scope="row">
                                {cell}
                              </StyledTableCell>
                            ))}

                            <StyledTableCell align="right">
                              <IconButton
                                btnClick={() =>
                                  removeListElement(templateField.id, index)
                                }
                                style={{ marginLeft: "auto" }}
                                icon="trash"
                              ></IconButton>
                            </StyledTableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </ClearTableContainer>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const proceed = () => {
    if (setErrors()) {
      setError("Uzupełnij wymagane pola");
    } else {
      const { errors, lists, ...rest } = metadata;
      const pairs = Object.entries(rest).filter(
        ([key, value]) => !Object.keys(lists).includes(`${key}`),
      );
      const metadataObj = { ...lists };
      pairs.forEach(pair => {
        Object.assign(metadataObj, { [pair[0]]: pair[1] });
      });

      next(metadataObj, contractor);
    }
  };

  return (
    <div className="form-group">
      <div className="flex-row a-center mb-4">
        {contractor && <p className="mr-2">{contractor.name}</p>}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectContractorModalOpen(true)}
        >
          {contractor ? "Zmień kontrahenta" : "Wybierz kontrahenta"}
        </Button>
      </div>
      {requiredFields
        .map(requiredField => getTemplateField(requiredField))
        .filter(templateField => !!templateField)
        .map(templateField => getFormInput(templateField))}

      <div className="actionsContainer">
        <div>
          {back && (
            <Button onClick={back} className="mr-1">
              Wróć
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={!contractor}
            onClick={proceed}
          >
            Dalej
          </Button>
        </div>
      </div>
      <p className="error">{error}</p>
      <SelectContractorDialog
        headerText="Wybierz kontrahenta"
        open={selectContractorModalOpen}
        close={() => setSelectContractorModalOpen(false)}
        setOpenNew={() => {
          setOpenNew(true);
          setSelectContractorModalOpen(false);
        }}
        selectContractor={hadnleSelectContractor}
      ></SelectContractorDialog>
      <EditContractorDialog
        open={openNew}
        contractor={{ name: "", fullName: "", tin: "" }}
        saveChanges={handleAdd}
        close={() => setOpenNew(false)}
      />
    </div>
  );
};

export default MetadataStep;
