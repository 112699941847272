import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";

import {
  loadDepartmentUsers,
  removeDepartmentUser,
} from "../../store/actions/departments.actions";
import {
  loadDepartments,
  addDepartment,
  editDepartment,
  addUserToDepartment,
} from "../../store/actions/departments.actions";

import DepartmentUsersList from "../../components/Departments/DepartmentUsersList";
import DepartmentModal from "../../components/Departments/DepartmentModal";
import AddUserToListModal from "../../components/Departments/AddUserToListModal";
import IconButton from "../../components/ui/IconButton/IconButton";
import ConfirmModal from "../../components/ui/ConfirmModal/ConfirmModal";
import "./DepartmentsPage.scss";

const DepartmentsPage = () => {
  const dispatch = useDispatch();
  const { departments, selectedDepartment } = useSelector(state => ({
    departments: state.departments.departments,
    selectedDepartment: state.departments.selectedDepartment,
  }));

  const [addDepartmentModalOpen, setAddDepartmentModalOpen] = useState(false);
  const [editDepartmentModalOpen, setEditDepartmentModalOpen] = useState(false);
  const [confirmRemoveModalOpen, setConfirmRemoveModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);

  const [
    addUserToDepartmentModalOpen,
    setAddUserToDepartmentModalOpen,
  ] = useState(false);

  const handleSelectDepartment = id => {
    dispatch(loadDepartmentUsers(id));
  };

  const handleRemoveUser = () => {
    dispatch(removeDepartmentUser(selectedDepartment.id, userToRemove));
    setConfirmRemoveModalOpen(false);
  };

  const handleAddDepartment = name => {
    dispatch(addDepartment(name));
    setAddDepartmentModalOpen(false);
  };

  const handleEditDepartment = name => {
    dispatch(editDepartment(selectedDepartment.id, name));
    setEditDepartmentModalOpen(false);
  };

  const handleAddUserToDepartment = user => {
    dispatch(addUserToDepartment(selectedDepartment.id, user));
    setAddUserToDepartmentModalOpen(false);
  };

  useEffect(() => {
    dispatch(loadDepartments());
  }, [dispatch]);

  return (
    <div className="departments-page">
      <div className="departments-menu panel">
        <div className="panel-title">
          <span>Działy</span>
          <IconButton
            onClick={() => setAddDepartmentModalOpen(true)}
            id="add-icon"
            icon="plus"
            className="icon-button primary"
          ></IconButton>
        </div>
        <List component="nav" dense aria-label="secondary mailbox folders">
          {departments &&
            departments.map((row, id) => (
              <ListItem
                key={id}
                button
                onClick={() => handleSelectDepartment(row._id)}
              >
                <ListItemText
                  primary={row.name}
                  className={
                    row._id === selectedDepartment.id ? "list-item-active" : ""
                  }
                />
              </ListItem>
            ))}
        </List>
      </div>

      {departments && departments.length > 0 && (
        <div className="panel">
          <div className="panel-title">
            Dział -{" "}
            {
              departments.find(
                department => department._id === selectedDepartment.id,
              ).name
            }
            <IconButton
              onClick={() => setEditDepartmentModalOpen(true)}
              id="add-icon"
              icon="pencil"
              className="icon-button primary"
            ></IconButton>
          </div>
          <h4>Użytkownicy</h4>
          {selectedDepartment.users.length > 0 && (
            <DepartmentUsersList
              users={selectedDepartment.users}
              remove={id => {
                setUserToRemove(id);
                setConfirmRemoveModalOpen(true);
              }}
            ></DepartmentUsersList>
          )}
          <div className="button-container">
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setAddUserToDepartmentModalOpen(true)}
            >
              Dodaj pracownika
            </Button>
          </div>
        </div>
      )}
      <DepartmentModal
        open={addDepartmentModalOpen}
        close={() => setAddDepartmentModalOpen(false)}
        click={handleAddDepartment}
        btnLabel="Dodaj"
        title="Dodaj dział"
      ></DepartmentModal>
      <DepartmentModal
        open={editDepartmentModalOpen}
        close={() => setEditDepartmentModalOpen(false)}
        click={handleEditDepartment}
        btnLabel="Edytuj"
        title="Edytuj dział"
      ></DepartmentModal>
      <AddUserToListModal
        headerText="Dodaj użytkownika do działu"
        open={addUserToDepartmentModalOpen}
        close={() => setAddUserToDepartmentModalOpen(false)}
        addUser={handleAddUserToDepartment}
        usersOnListIds={selectedDepartment.users.map(user => user._id)}
      ></AddUserToListModal>
      <ConfirmModal
        title="Czy na pewno chcesz usunąć użytkownika z działu?"
        open={confirmRemoveModalOpen}
        close={() => setConfirmRemoveModalOpen(false)}
        confirm={handleRemoveUser}
      ></ConfirmModal>
    </div>
  );
};

export default DepartmentsPage;
