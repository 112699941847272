import { handleError } from "../../helpers/handleError";
import axios from "axios";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const MARK_AS_READ = "MARK_AS_READ";
export const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";
export const MARK_AS_READ_FAILURE = "MARK_AS_READ_FAILURE";

export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ";
export const MARK_ALL_AS_READ_SUCCESS = "MARK_ALL_AS_READ_SUCCESS";
export const MARK_ALL_AS_READ_FAILURE = "MARK_ALL_AS_READ_FAILURE";

export const CHANGE_NOTIFICATIONS_PAGE = "CHANGE_NOTIFICATIONS_PAGE";

const baseUrl = process.env.REACT_APP_API_URL;

export const changePage = page => {
  return {
    type: CHANGE_NOTIFICATIONS_PAGE,
    page,
  };
};

export const getNotifications = (page, rowsPerPage = 10) => {
  return dispatch => {
    dispatch({ type: GET_NOTIFICATIONS });
    axios
      .get(`${baseUrl}api/notifications`)
      .then(response => {
        dispatch(getNotificationsSuccess(response.data));
      })
      .catch(error => dispatch(getNotificationsFailure(error)));
  };
};

export const getNotificationsSuccess = notifications => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    notifications,
  };
};

export const getNotificationsFailure = error => {
  handleError(error);
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    error: error,
  };
};

export const markAsRead = id => {
  return dispatch => {
    dispatch({ type: MARK_AS_READ });
    axios
      .put(`${baseUrl}api/notifications/${id}/mark-as-read`)
      .then(() => {
        dispatch(markAsReadSuccess(id));
      })
      .catch(error => dispatch(markAsReadFailure(error)));
  };
};

export const markAsReadSuccess = id => {
  return {
    type: MARK_AS_READ_SUCCESS,
    id,
  };
};

export const markAsReadFailure = error => {
  handleError(error);
  return {
    type: MARK_AS_READ_SUCCESS,
    error: error,
  };
};

export const markAllAsRead = () => {
  return dispatch => {
    dispatch({ type: MARK_ALL_AS_READ });
    axios
      .put(`${baseUrl}api/notifications/mark-all-as-read`)
      .then(() => {
        dispatch(markAllAsReadSuccess());
      })
      .catch(error => dispatch(markAllAsReadFailure(error)));
  };
};

export const markAllAsReadSuccess = () => {
  return {
    type: MARK_ALL_AS_READ_SUCCESS,
  };
};

export const markAllAsReadFailure = error => {
  handleError(error);
  return {
    type: MARK_ALL_AS_READ_FAILURE,
    error: error,
  };
};
