/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { loadComments, addComment } from "../../store/actions/comments.actions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const Comments = props => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");

  const { count, comments, user } = useSelector(state => ({
    count: state.comments.count,
    comments: state.comments.comments,
    user: state.auth.user,
  }));

  useEffect(() => {
    dispatch(loadComments(props.contractId));
  }, []);

  const addNewComment = () => {
    dispatch(addComment(props.contractId, text, user));
    setText("");
  };

  const handleChange = event => {
    setText(event.target.value);
  };

  return (
    <div className="panel j-between p-4">
      <p>Komentarze ({count})</p>
      {count > 0 &&
        comments.map((comment, id) => (
          <div key={id} className="my-4">
            <p className="font-s mb-2">
              <span className="bold">
                {comment.user.firstName + " " + comment.user.lastName}
              </span>
              <span className="dot"></span>
              <span> {moment(comment.createdAt).format("DD-MM-YYYY")}</span>
            </p>

            <p className="font-s">{comment.text}</p>
          </div>
        ))}
      <div className="flex-column">
        <TextField
          id="filled-basic"
          multiline
          value={text}
          onChange={handleChange}
          rows={4}
          className={"mb-3"}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "auto" }}
          onClick={() => addNewComment()}
        >
          Wyślij
        </Button>
      </div>
    </div>
  );
};

export default Comments;
