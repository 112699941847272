import { handleError } from "../../helpers/handleError";
import axios from "axios";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const LOAD_CONTRACTORS = "LOAD_CONTRACTORS";
export const LOAD_CONTRACTORS_SUCCESS = "LOAD_CONTRACTORS_SUCCESS";
export const LOAD_CONTRACTORS_FAILURE = "LOAD_CONTRACTORS_FAILURE";

export const UPDATE_CONTRACTORS = "UPDATE_CONTRACTORS";
export const UPDATE_CONTRACTORS_SUCCESS = "UPDATE_CONTRACTORS_SUCCESS";
export const UPDATE_CONTRACTORS_FAILURE = "UPDATE_CONTRACTORS_FAILURE";

export const ADD_CONTRACTORS = "ADD_CONTRACTORS";
export const ADD_CONTRACTORS_SUCCESS = "ADD_CONTRACTORS_SUCCESS";
export const ADD_CONTRACTORS_FAILURE = "ADD_CONTRACTORS_FAILURE";

export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";

const baseUrl = process.env.REACT_APP_API_URL;

export const changePage = page => {
  return {
    type: CHANGE_PAGE,
    page,
  };
};

export const loadContractors = (page, searchString, rowsPerPage = 5) => {
  return dispatch => {
    dispatch({ type: LOAD_CONTRACTORS });
    axios
      .get(
        `${baseUrl}api/contractors?skip=${page * rowsPerPage}${
          searchString ? `&searchString=${searchString}` : ""
        }`,
      )
      .then(response => {
        dispatch(
          loadContractorsSuccess(response.data.data, response.data.count, page),
        );
      })
      .catch(error => dispatch(loadContractorsFailure(error)));
  };
};

export const loadContractorsSuccess = (contractors, count, page) => {
  return {
    type: LOAD_CONTRACTORS_SUCCESS,
    contractors,
    count,
    page,
  };
};

export const loadContractorsFailure = error => {
  handleError(error);
  return {
    type: LOAD_CONTRACTORS_FAILURE,
    error: error,
  };
};

export const updateContractor = (contractorId, contractor) => {
  return dispatch => {
    dispatch({ type: UPDATE_CONTRACTORS });
    axios
      .put(`${baseUrl}api/contractors/${contractorId}`, contractor)
      .then(response => {
        dispatch(updatecontractorsSuccess(contractorId, contractor));
      })
      .catch(error => dispatch(updateContractorFailure(error)));
  };
};

export const updatecontractorsSuccess = (contractorId, contractor) => {
  return {
    type: UPDATE_CONTRACTORS_SUCCESS,
    contractorId,
    contractor,
  };
};

export const updateContractorFailure = error => {
  handleError(error);
  return {
    type: UPDATE_CONTRACTORS_FAILURE,
    error: error,
  };
};

export const addContractor = contractor => {
  return dispatch => {
    dispatch({ type: ADD_CONTRACTORS });
    axios
      .post(`${baseUrl}api/contractors`, contractor)
      .then(response => {
        dispatch(addcontractorsSuccess(response.data));
      })
      .catch(error => dispatch(addContractorFailure(error)));
  };
};

export const addcontractorsSuccess = contractor => {
  return {
    type: ADD_CONTRACTORS_SUCCESS,
    contractor,
  };
};

export const addContractorFailure = error => {
  handleError(error);
  return {
    type: ADD_CONTRACTORS_FAILURE,
    error: error,
  };
};

export const setSearchFilter = searchString => {
  return dispatch => {
    dispatch({ type: SET_SEARCH_FILTER, searchString });
    dispatch(loadContractors(0, searchString));
  };
};
