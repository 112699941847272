import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));
