import React, { useState } from "react";
import IconButton from "../ui/IconButton/IconButton";
import Button from "@material-ui/core/Button";
import {
  addAttachment,
  deleteAttachment,
} from "../../store/actions/newDocument.actions";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";

const AttachmentStep = props => {
  const dispatch = useDispatch();
  const { back, add } = props;
  const [ fileValue, changeFileValue ] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);

  const { newDocument } = useSelector(state => ({
    newDocument: state.newDocument,
  }));

  const handleChange = event => {
    setDescription(event.target.value);
  };

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      changeFileValue(e.target.value)
      setFile(formData);
    }
  };

  const handleFileAdd = e => {
    dispatch(addAttachment(newDocument.documentId, file, description));
    setDescription("");
    setFile(null);
    changeFileValue('')
  };

  return (
    <React.Fragment>
      <h3>Załączniki</h3>
      {newDocument.attachments.length > 0 && (
        <div className="mt-2 mb-3">
          <div className="flex-row">
            <p
              className="caption mr-2"
              style={{
                width: "300px",
              }}
            >
              Nazwa pliku
            </p>
            <p className="caption">Opis</p>
          </div>
          {newDocument.attachments.map(attachment => (
            <div key={attachment._id} className="flex-row a-center mb-3">
              <span
                className="mr-2 link"
                style={{
                  width: "300px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {attachment.originalFileName}
              </span>
              <span className="mr-4">{attachment.description}</span>
              <IconButton
                icon="trash"
                onClick={() =>
                  dispatch(
                    deleteAttachment(newDocument.documentId, attachment._id),
                  )
                }
              ></IconButton>
            </div>
          ))}
        </div>
      )}
      <div className="mt-3 flex-row">
        <div className="mr-4" style={{ minWidth: "50%" }}>
          <TextField
            label="Opis załącznika (opcjonalny)"
            multiline
            style={{ width: "100%" }}
            value={description}
            onChange={handleChange}
            rows={2}
          />
        </div>
        <div className="mt-3 flex-row" style={{ width: "100%" }}>
          <div className="button-wrapper">
            <div className="label">
              <span className="mr-3 link">{file?.get("file").name}</span>
              <span className="mr-2">{file ? "" : "Wybierz plik"} </span>
              <IconButton icon="file"></IconButton>
            </div>
            <input
              type="file"
              name="upload"
              id="upload"
              className="upload-box"
              placeholder="Upload File"
              value={fileValue}
              onChange={event => handleFileSelect(event)}
            ></input>
          </div>
          {file && (
            <IconButton onClick={handleFileAdd} icon="plus"></IconButton>
          )}
        </div>
      </div>

      <div className="actionsContainer">
        <div>
          {back && (
            <Button onClick={back} className="mr-1">
              Wróć
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={add}>
            Dodaj umowę
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AttachmentStep;
