import * as actionTypes from "../actions/comments.actions";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  comments: [],
  count: 0,
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_COMMENTS_SUCCESS:
      return {
        count: action.count,
        comments: action.comments,
      };
    case actionTypes.ADD_COMMENT_SUCCESS:
      return {
        count: state.count + 1,
        comments: [
          ...cloneDeep(state.comments),
          { text: action.text, user: action.author, createdAt: new Date() },
        ],
      };
    default:
      return state;
  }
};

export default commentReducer;
