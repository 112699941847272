import * as actionTypes from "../actions/templates.actions";

const initialState = {
  templates: null,
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DEPARTMENT_TEMPLATES_SUCCESS: {
      return {
        templates: action.templates,
      };
    }
    case actionTypes.ADD_TEMPLATE_SUCCESS: {
      let newTemplates = state.templates.map(template => ({
        ...template,
        requiredFields: [...template.requiredFields],
      }));
      newTemplates.push(action.template);

      return {
        templates: newTemplates,
      };
    }
    case actionTypes.EDIT_TEMPLATE_SUCCESS: {
      let newTemplates = state.templates.map(template => ({
        ...template,
        requiredFields: [...template.requiredFields],
      }));
      const index = state.templates.findIndex(
        template => template._id === action.templateId,
      );
      if (index !== -1) {
        newTemplates[index].name = action.name;
        newTemplates[index].requiredFields = [...action.requiredFields];
      }

      return {
        templates: newTemplates,
      };
    }
    case actionTypes.DELETE_TEMPLATE_SUCCESS: {
      let newTemplates = state.templates.map(template => ({
        ...template,
        requiredFields: [...template.requiredFields],
      }));
      const index = state.templates.findIndex(
        template => template._id === action.templateId,
      );
      if (index !== -1) {
        newTemplates.splice(index, 1);
      }

      return {
        templates: newTemplates,
      };
    }
    default:
      return state;
  }
};

export default templatesReducer;
