import React from "react";
import { Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "./App.scss";
import { history } from "./helpers/history";
import Routes from "./Routes";
import thunk from "redux-thunk";
import authReducer from "./store/reducers/authentication.reducer";
import usersReducer from "./store/reducers/users.reducers";
import departmentsReducer from "./store/reducers/departments.reducers";
import templatesReducer from "./store/reducers/templates.reducer";
import uiReducer from "./store/reducers/ui.reducer";
import agreementReducer from "./store/reducers/agreements.reducer";
import newAgreementReducer from "./store/reducers/newAgreement.reducer";
import newDocumentReducer from "./store/reducers/newDocument.reducer";
import notificationsReducer from "./store/reducers/notifications.reducer";
import CustomSnackbar from "./components/ui/CustomSnackbar/CustomSnackbar";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./interceptors.js";
import commentReducer from "./store/reducers/comment.reducer";
import contractorsReducer from "./store/reducers/contractors.reducers";

const rooteReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  departments: departmentsReducer,
  templates: templatesReducer,
  ui: uiReducer,
  agreement: agreementReducer,
  newAgreement: newAgreementReducer,
  newDocument: newDocumentReducer,
  comments: commentReducer,
  notifications: notificationsReducer,
  contractors: contractorsReducer,
});

const theme = createMuiTheme({
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#7A4ADC",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
      sizeLarge: {
        lineHeight: 2.5,
      },
    },
    MuiTypography: {
      body2: {
        fontWeight: "unset",
      },
    },
    MuiFormControlLabel: {
      labelPlacementTop: {
        alignItems: "flex-start",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "none",
      },
      formControl: {
        transform: "none",
        fontSize: "0.8rem",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "unset",
        "&$focused": {
          color: "unset",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          display: "none",
        },
        "&:after": {
          display: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        marginTop: "24px !important",
        border: "none",
        backgroundColor: "#EDEEF2",
        "&:hover": {
          backgroundColor: "#E8EAEF",
        },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        padding: "10px 26px 10px 12px",
      },
      inputMultiline: {
        padding: "10px 26px 10px 12px",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "3px 3px 25px 0px rgba(3, 0, 75, 0.03)",
      },
    },
    MuiAccordion: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
  },

  // palette: {
  //   primary: {
  //     main: "#7A4ADC",
  //   },
  //   secondary: {
  //     light: "#0066ff",
  //     main: "#0044ff",
  //     contrastText: "#ffcc00",
  //   },
  //   contrastThreshold: 3,
  //   tonalOffset: 0.2,
  // },
  palette: {
    primary: {
      main: "#223f79",
    },
    secondary: {
      light: "#C20016",
      main: "#C20016",
      contrastText: "#ffcc00",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rooteReducer, composeEnhancers(applyMiddleware(thunk)));

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <CustomSnackbar />
            <Routes></Routes>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
