import * as actionTypes from "../actions/departments.actions";

const initialState = {
  departments: null,
  selectedDepartment: {
    id: null,
    users: [],
  },
};

const departmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.departments,
        selectedDepartment: {
          id: action.departments[0]._id,
          users: [...state.selectedDepartment.users],
        },
      };
    }
    case actionTypes.LOAD_DEPARTMENT_USERS: {
      return {
        ...state,
        selectedDepartment: {
          ...state.selectedDepartment,
          id: action.id,
        },
      };
    }
    case actionTypes.LOAD_DEPARTMENT_USERS_SUCCESS: {
      return {
        ...state,
        selectedDepartment: {
          id: state.selectedDepartment.id,
          users: action.users.map(user => ({ ...user })),
        },
      };
    }
    case actionTypes.REMOVE_DEPARTMENT_USER_SUCCESS: {
      let newUsers = state.selectedDepartment.users.map(user => ({ ...user }));
      if (state.selectedDepartment.id === action.departmentId) {
        const userIndex = state.selectedDepartment.users.findIndex(
          user => user._id === action.userId,
        );
        if (userIndex !== -1) {
          newUsers.splice(userIndex, 1);
        }
      }
      return {
        ...state,
        selectedDepartment: {
          id: state.selectedDepartment.id,
          users: newUsers,
        },
      };
    }
    case actionTypes.ADD_DEPARTMENT_SUCCESS: {
      let newDepartments = state.departments.map(department => ({
        ...department,
      }));
      newDepartments.push(action.department);
      return {
        ...state,
        departments: newDepartments,
      };
    }
    case actionTypes.ADD_USER_TO_DEPARTMENT_SUCCESS: {
      let newUsers = state.selectedDepartment.users.map(user => ({ ...user }));
      newUsers.push(action.user);

      return {
        ...state,
        selectedDepartment: {
          id: state.selectedDepartment.id,
          users: newUsers,
        },
      };
    }
    case actionTypes.EDIT_DEPARTMENT_SUCCESS: {
      let newDepartments = state.departments.map(department => ({
        ...department,
      }));
      const index = newDepartments.findIndex(
        department => department._id === action.departmentId,
      );
      newDepartments[index].name = action.name;

      return {
        ...state,
        departments: newDepartments,
      };
    }
    default:
      return state;
  }
};

export default departmentsReducer;
