import { handleError } from "../../helpers/handleError";
import axios from "axios";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_FAILURE = "LOAD_USERS_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const SELECT_DEPARTMENT_USERS = "SELECT_DEPARTMENT_USERS";

const baseUrl = process.env.REACT_APP_API_URL;

export const changePage = page => {
  return {
    type: CHANGE_PAGE,
    page,
  };
};

export const loadUsers = (page, filters, rowsPerPage = 10) => {
  return dispatch => {
    dispatch({ type: LOAD_USERS });
    axios
      .get(
        `${baseUrl}api/users?skip=${page * rowsPerPage}${
          filters.department ? `&departmentId=${filters.department}` : ""
        }`,
      )
      .then(response => {
        dispatch(
          loadUsersSuccess(response.data.data, response.data.count, page),
        );
      })
      .catch(error => dispatch(loadUsersFailure(error)));
  };
};

export const loadUsersSuccess = (users, count, page) => {
  return {
    type: LOAD_USERS_SUCCESS,
    users,
    count,
    page,
  };
};

export const loadUsersFailure = error => {
  handleError(error);
  return {
    type: LOAD_USERS_FAILURE,
    error: error,
  };
};

export const updateUser = (id, isAdmin) => {
  return dispatch => {
    dispatch({ type: UPDATE_USER });
    axios
      .put(`${baseUrl}api/users/${id}`, { isAdmin })
      .then(response => {
        dispatch(updateUsersSuccess(id, isAdmin));
      })
      .catch(error => dispatch(updateUserFailure(error)));
  };
};

export const updateUsersSuccess = (id, isAdmin) => {
  return {
    type: UPDATE_USER_SUCCESS,
    id,
    isAdmin,
  };
};

export const updateUserFailure = error => {
  handleError(error);
  return {
    type: UPDATE_USER_FAILURE,
    error: error,
  };
};

export const selectDepartment = filters => {
  return dispatch => {
    dispatch({ type: SELECT_DEPARTMENT_USERS, department: filters.department });
    dispatch(loadUsers(0, filters));
  };
};
