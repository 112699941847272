/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { StyledTableContainer, StyledTableCell, StyledTableHead } from "../../helpers/table-styles";
import { getNotifications, changePage, markAsRead, markAllAsRead } from "../../store/actions/notifications.actions";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import moment from "moment";
import "./NotificationsPage.scss";
import sanitizeHtml from 'sanitize-html';

const NotifiactionsPage = () => {
  const dispatch = useDispatch();

  const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
      dirty, {
        allowedTags: sanitizeHtml.defaults.allowedTags
      }
    )
  });
  
  const SanitizeHTML = ({ html }) => (
    <div dangerouslySetInnerHTML={sanitize(html)} />
  );

  const { loading, notifications, count, page } = useSelector(state => ({
    loading: state.notifications.loading,
    notifications: state.notifications.notifications,
    count: state.notifications.count,
    page: state.notifications.page,
  }));

  const handleChangePage = (event, newPage) => {
    if (newPage * 10 >= notifications.length) {
      dispatch(getNotifications(newPage));
    } else {
      dispatch(changePage(newPage));
    }
  };

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(getNotifications(0));
    }
  }, []);

  return (
    <div className="table-contaier">
      <Button
        onClick={() => dispatch(markAllAsRead())}
        variant="contained"
        color="primary"
        className="ml-auto mb-4"
        style={{ display: "flex" }}
      >
        Oznacz wszystkie jako przeczytane
      </Button>
      {count > 0 && (
        <React.Fragment>
          <StyledTableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>Powiadomienie</StyledTableCell>
                  <StyledTableCell>Data</StyledTableCell>
                  <StyledTableCell>Oznacz</StyledTableCell>
                </TableRow>
              </StyledTableHead>
              {console.log(page, page * 10, page * 10 + 10, notifications.slice(page * 10, page * 10 + 10))}
              <TableBody>
                {notifications.slice(page * 10, page * 10 + 10).map(row => (
                  <TableRow key={row._id} className={row.seen ? "notification-seen" : "notification"}>
                    <StyledTableCell style={{ wordBreak: "break-all" }}>
                      <SanitizeHTML html={row.text} />
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: "150px" }}> {moment(row.createdAt).format("DD-MM-YYYY hh:mm")}</StyledTableCell>
                    <StyledTableCell>
                      {!row.seen && (
                        <Button variant="contained" color="primary" onClick={() => dispatch(markAsRead(row._id))}>
                          Oznacz jako przeczytane
                        </Button>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPage={10}
              count={count}
              rowsPerPageOptions={[]}
              page={page}
              nextIconButtonProps={{
                disabled: loading || page + 1 >= Math.ceil(count / 10),
              }}
              backIconButtonProps={{ disabled: loading || page === 0 }}
              onChangePage={handleChangePage}
            />
          </StyledTableContainer>
        </React.Fragment>
      )}
    </div>
  );
};

export default NotifiactionsPage;
