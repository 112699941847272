import React from "react";
import { Route } from "react-router-dom";

import { useSelector } from "react-redux";

export const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        allowed ? <Component {...props} /> : <p>Redirect...</p>
      }
    />
  );
};

export const AdminRoute = ({ component: Component, allowed, ...rest }) => {
  const { user, loggedIn } = useSelector(state => ({
    user: state.auth.user,
    loggedIn: state.auth.loggedIn,
  }));

  return (
    <PrivateRoute
      component={Component}
      allowed={user && user.isAdmin && loggedIn}
      {...rest}
    ></PrivateRoute>
  );
};

export const UserRoute = ({ component: Component, allowed, ...rest }) => {
  const { loggedIn } = useSelector(state => ({
    loggedIn: state.auth.loggedIn,
  }));

  return (
    <PrivateRoute
      component={Component}
      allowed={loggedIn}
      {...rest}
    ></PrivateRoute>
  );
};
