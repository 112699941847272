import React, { Component } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import Topbar from "./components/Topbar/Topbar";
import queryString from "query-string";
import { login, getPartnerInfo } from "./store/actions/authentication.actions";
import { AdminRoute, UserRoute } from "./components/PrivateRoute/PrivateRoute";
import UsersPage from "./pages/UsersPage/UsersPage";
import DocumentsPage from "./pages/DocumentsPage/DocumentsPage";
import DepartmentsPage from "./pages/DepartmentsPage/DepartmentsPage";
import TemplatesPage from "./pages/TemplatesPage/TemplatesPage";
import AddAgreementPage from "./pages/AddAgreementPage/AddAgreementPage";
import AgreementPage from "./pages/AgreementPage/AgreementPage";
import { connect } from "react-redux";
import { compose } from "redux";
import AddDocumentPage from "./pages/AddDocumentPage/AddDocumentPage";
import EditAgreementPage from "./pages/EditAgreementPage/EditAgreementPage";
import NotifiactionsPage from "./pages/NotificationsPage/NotificationsPage";
import ContractorsPage from "./pages/ContractorsPage/ContractorsPage";
import LoginPage from "./pages/LoginPage/LoginPage";
const baseUrl = process.env.REACT_APP_API_URL;

class Routes extends Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const partnerId = localStorage.getItem("partnerId");

    if (this.props.location.pathname !== "/login") {
      if (!partnerId) {
        this.props.getPartnerInfo(window.location.hostname);
      }

      if (!this.props.user && this.props.token) {
        this.props.login(this.props.token);
      } else if (params.accessToken) {
        this.props.login(params.accessToken);
      }

      if (!!partnerId && !params.accessToken && !this.props.token) {
        window.location.replace(`${baseUrl}api/auth?partnerId=${partnerId}`);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user && <Topbar />}
        <div className="layout">
          <Route path="/login" component={LoginPage} />
          <UserRoute
            path="/documents"
            exact
            component={DocumentsPage}
            authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/new-agreement"
            exact
            component={AddAgreementPage}
            authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/new-document/:agreementId"
            exact
            component={AddDocumentPage}
            authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/edit-agreement/:agreementId"
            exact
            component={EditAgreementPage}
            authed={this.props.loggedIn}
          />
          <UserRoute
            path="/documents/:documentId"
            exact
            component={AgreementPage}
            authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/users"
            component={UsersPage}
            authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/contractors"
            exact
            component={ContractorsPage}
            authed={this.props.loggedIn}
          />
          <UserRoute
          path="/notifications"
          component={NotifiactionsPage}
          authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/departments"
            component={DepartmentsPage}
            authed={this.props.loggedIn}
          />
          <AdminRoute
            path="/templates"
            component={TemplatesPage}
            authed={this.props.loggedIn}
          />
          <UserRoute exact path="/">
            <Redirect to="/documents" />
          </UserRoute>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  token: state.auth.token,
  partnerInfo: state.auth.partnerInfo,
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => {
  return {
    login: token => dispatch(login(token)),
    getPartnerInfo: domain => dispatch(getPartnerInfo(domain)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Routes);
