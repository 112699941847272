import * as actionTypes from "../actions/users.actions";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  loading: false,
  users: [],
  count: null,
  page: 0,
  filters: {
    department: null,
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_DEPARTMENT_USERS: {
      return {
        ...cloneDeep(state),
        users: [],
        count: null,
        page: 0,
        filters: {
          ...state.filters,
          department: action.department,
        },
      };
    }
    case actionTypes.LOAD_USERS: {
      return {
        ...cloneDeep(state),
        loading: true,
      };
    }
    case actionTypes.LOAD_USERS_SUCCESS: {
      const newUsers = state.users.map(user => ({ ...user }));
      newUsers.push(...action.users);
      return {
        ...cloneDeep(state),
        count: action.count,
        users: newUsers,
        page: action.page,
        loading: false,
      };
    }
    case actionTypes.CHANGE_PAGE: {
      return {
        ...cloneDeep(state),
        page: action.page,
      };
    }
    case actionTypes.UPDATE_USER_SUCCESS: {
      let newUsers = cloneDeep(state.users);
      const index = newUsers.findIndex(user => user._id === action.id);
      newUsers[index].isAdmin = action.isAdmin;
      return {
        ...cloneDeep(state),
        users: newUsers,
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
