import React from "react";
import Modal from "@material-ui/core/Modal";
import TemplateDetails from "../TemplateDetails/TemplateDetails";

const AddTemplateModal = props => {
  const { open, close, addTemplate } = props;

  return (
    <Modal open={open} onClose={close}>
      <div className="modal add-template-modal">
        <div className="modal-title">
          <p>Dodaj szablon</p>
        </div>
        <div className="modal-content">
          <TemplateDetails
            name=""
            fieldNames={[]}
            btnLabel={"Dodaj"}
            handleClick={addTemplate}
          ></TemplateDetails>
        </div>
      </div>
    </Modal>
  );
};

export default AddTemplateModal;
