import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddUserToListModal from "../Departments/AddUserToListModal";
import Button from "@material-ui/core/Button";
import {
  addUser,
  deleteUser,
  setUsers,
  selectKeeper,
} from "../../store/actions/newAgreement.actions";

import SVG from "react-inlinesvg";

const UsersStep = props => {
  const dispatch = useDispatch();
  const { back, next } = props;
  const { selectedDepartmentUsers, users, keeper } = useSelector(state => ({
    selectedDepartmentUsers: state.departments.selectedDepartment.users,
    users: state.newAgreement.users,
    keeper: state.newAgreement.keeper,
  }));

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [selectKeeperModalOpen, setSelectKeeperModalOpen] = useState(false);

  const handleAddUser = user => {
    dispatch(addUser(user));
    setAddUserModalOpen(false);
  };

  const handleSelectKeeper = user => {
    dispatch(selectKeeper(user));
    setSelectKeeperModalOpen(false);
  };

  useEffect(() => {
    dispatch(setUsers(selectedDepartmentUsers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentUsers]);

  return (
    <div>
      <div className="mb-5 mt-3">
        <p className="font-s bold mb-3">Opiekun dokumentu</p>
        <div className="flex-row a-center mb-3">
          {keeper && (
            <p className="mr-2">{keeper.firstName + " " + keeper.lastName}</p>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectKeeperModalOpen(true)}
          >
            {keeper ? "Zmień opiekuna" : "Wybierz opiekuna"}
          </Button>
        </div>
      </div>
      {users && users.length > 0 && (
        <>
          <div>
            <p className="font-s bold mb-3">
              Użytkownicy, którzy będą mieć dostęp do dokumentu
            </p>
            <div className="flex-row flex-wrap mt-3">
              {users.map(user => (
                <div key={user._id} className="badge">
                  {user.firstName + " " + user.lastName}
                  <SVG
                    onClick={() => dispatch(deleteUser(user._id))}
                    className="icon btn-close"
                    style={{
                      fill: "white",
                      height: "0.6rem",
                      cursos: "pointer",
                    }}
                    src={`/icons/close.svg`}
                  ></SVG>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {users && users.length === 0 && (
        <p className="caption">Brak użytkowników w podanym dziale</p>
      )}
      <Button
        className="mt-3"
        variant="contained"
        color="primary"
        onClick={() => setAddUserModalOpen(true)}
      >
        Dodaj pracownika
      </Button>
      <AddUserToListModal
        headerText="Wybierz użytkownika, który ma mieć dostęp do dokumentu"
        open={addUserModalOpen}
        close={() => setAddUserModalOpen(false)}
        addUser={handleAddUser}
        usersOnListIds={users ? users.map(user => user._id) : []}
      ></AddUserToListModal>
      <AddUserToListModal
        headerText="Wybierz opiekuna"
        open={selectKeeperModalOpen}
        close={() => setSelectKeeperModalOpen(false)}
        addUser={handleSelectKeeper}
        usersOnListIds={[]}
      ></AddUserToListModal>
      <div className="actionsContainer">
        <div>
          <Button onClick={back} className="mr-1">
            Wróć
          </Button>
          <Button variant="contained" color="primary" onClick={next}>
            Dalej
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UsersStep;
