/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "../../components/ui/IconButton/IconButton";
import CustomInput, {
  StyledInput,
} from "../../components/ui/CustomInput/CustomInput";
import {
  StyledTableContainer,
  StyledTableCell,
  StyledTableHead,
} from "../../helpers/table-styles";
import {
  loadContractors,
  changePage,
  setSearchFilter,
  updateContractor,
  addContractor,
} from "../../store/actions/contractors.actions";
import { useDispatch, useSelector } from "react-redux";
import EditContractorDialog from "../../components/EditContractorDialog/EditContractorDialog";

const ContractorsPage = () => {
  const [rowsPerPage] = useState(5);
  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedContractor, setContractor] = useState(null);

  const dispatch = useDispatch();

  const { loading, contractors, count, page, filters } = useSelector(state => ({
    loading: state.contractors.loading,
    contractors: state.contractors.contractors,
    count: state.contractors.count,
    page: state.contractors.page,
    filters: state.contractors.filters,
  }));

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (!contractors) {
      dispatch(loadContractors(0));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    if (newPage * 5 >= contractors.length) {
      dispatch(loadContractors(newPage, filters));
    } else {
      dispatch(changePage(newPage));
    }
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setSearchString(value);
    if (!loading) {
      dispatch(setSearchFilter(value !== "" ? value : null));
    }
  };

  const handleEdit = contractor => {
    setOpenEdit(true);
    setContractor({ ...contractor });
  };

  const handleSaveChanges = contractor => {
    dispatch(updateContractor(selectedContractor._id, contractor));
    setOpenEdit(false);
  };

  const handleAdd = contractor => {
    dispatch(addContractor(contractor));
    setOpenNew(false);
  };

  return (
    <div className="table-contaier">
      <Button
        onClick={() => setOpenNew(true)}
        variant="contained"
        color="primary"
        className="mb-4"
      >
        Dodaj kontrahenta
      </Button>
      <EditContractorDialog
        open={openNew}
        contractor={{ name: "", fullName: "", tin: "" }}
        saveChanges={handleAdd}
        close={() => setOpenNew(false)}
      />
      {/* <CustomSelect
        label="Dział"
        className="mb-4"
        changeOption={selectDepartmentOption}
        selectedOption={selectedDepartment}
        options={departmentOptions}
        theme="dark"
      ></CustomSelect> */}

      <React.Fragment>
        <StyledTableContainer>
          <CustomInput className="mr-4 mb-3" label="Szukaj kontrahenta">
            <StyledInput
              name={"name"}
              value={searchString}
              onChange={event => handleInputChange(event)}
            />
          </CustomInput>
          {count > 0 && (
            <>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Nazwa</StyledTableCell>
                    <StyledTableCell>Pełna nazwa</StyledTableCell>
                    <StyledTableCell>NIP</StyledTableCell>
                    <StyledTableCell align="right">Edycja</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {contractors
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow key={row._id}>
                        <StyledTableCell>{row.name}</StyledTableCell>
                        <StyledTableCell>{row.fullName}</StyledTableCell>
                        <StyledTableCell>{row.tin}</StyledTableCell>
                        <StyledTableCell align="right">
                          <IconButton
                            disabled={!!row.sub}
                            btnClick={() => handleEdit(row)}
                            style={{ marginLeft: "auto" }}
                            icon="pencil"
                          ></IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                rowsPerPage={rowsPerPage}
                count={count}
                rowsPerPageOptions={[]}
                page={page}
                nextIconButtonProps={{
                  disabled: loading || page + 1 >= Math.ceil(count / rowsPerPage),
                }}
                backIconButtonProps={{ disabled: loading || page === 0 }}
                onChangePage={handleChangePage}
              />
            </>
          )}
        </StyledTableContainer>
        {selectedContractor && (
          <EditContractorDialog
            open={openEdit}
            contractor={selectedContractor}
            saveChanges={handleSaveChanges}
            close={() => setOpenEdit(false)}
          />
        )}
      </React.Fragment>
    </div>
  );
};

export default ContractorsPage;
