import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import SimpleTable from "../../components/ui/SimpleTable/SimpleTable";
import { typeOptions } from "../../helpers/template-fields-list";
import moment from "moment";
import IconButton from "../ui/IconButton/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { getAttachment } from "../../store/actions/newDocument.actions";
import {
  approveDocument,
  acceptDocument,
} from "../../store/actions/agreements.actions";
import SVG from "react-inlinesvg";
import Button from "@material-ui/core/Button";

const DocumentDetails = props => {
  const dispatch = useDispatch();
  const { document, isMain, open, id } = props;
  const [expanded, setEpanded] = useState(open);

  const downloadFile = (documentId, attachmentId, name) => {
    dispatch(getAttachment(documentId, attachmentId, name, true));
  };

  const openFile = (documentId, attachmentId, name) => {
    dispatch(getAttachment(documentId, attachmentId, name));
  };

  const { user } = useSelector(state => ({
    user: state.auth.user,
  }));

  return (
    <div className="step-container ml-4 pb-4">
      <div className="step-number">{id}</div>
      <div
        className={
          expanded
            ? "accordion-expand-button expanded"
            : "accordion-expand-button"
        }
        onClick={() => setEpanded(!expanded)}
      >
        <SVG className="icon" src="/icons/arrow-down.svg"></SVG>
      </div>
      <Accordion expanded={expanded} key={document._id} className="ml-4 p-3">
        <AccordionSummary>
          <div className="flex-row j-between" style={{ width: "100%" }}>
            <p className="bold mr-5">{typeOptions[document.type]}</p>
            <div className="mr-5">
              <p className="caption mb-3">Data początkowa</p>{" "}
              <p>{moment(document.startDate).format("DD-MM-YYYY")}</p>
            </div>
            <div className="mr-5">
              <p className="caption mb-3">Data końcowa</p>{" "}
             {document.endDate ? <p>{moment(document.endDate).format("DD-MM-YYYY")}</p> : 'Umowa na czas nieokreślony'} 
            </div>
            {document.metadata.contract_subject && (
              <div className="mr-5">
                <p className="caption mb-3">Przedmiot umowy</p>{" "}
                <p>{document.metadata.contract_subject}</p>
              </div>
            )}
            {document.metadata.contract_execution_term && (
              <div className="mr-5">
                <p className="caption mb-3">Termin realizacji</p>{" "}
                <p>
                  {moment(document.metadata.contract_execution_term).format(
                    "DD-MM-YYYY",
                  )}
                </p>
              </div>
            )}
            {isMain &&
              document.status !== "APPROVED" &&
              document.assignedUsers.find(
                assignedUser => assignedUser.user._id === user._id,
              ) &&
              !document.assignedUsers.find(
                assignedUser => assignedUser.user._id === user._id,
              ).isAccepted && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className="mr-3"
                  onClick={() =>
                    dispatch(acceptDocument(document._id, user._id))
                  }
                >
                  Zaakceptuj dokument
                </Button>
              )}
            {user.isAdmin && document.status !== "APPROVED" && isMain && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="mr-3"
                onClick={() => dispatch(approveDocument(document._id))}
              >
                Zatwierdź dokument
              </Button>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-5">
            <div className="flex-row flex-wrap">
              {document.metadata.maturity && (
                <div className="mr-6 mb-4">
                  <p className="caption mb-3">Termin płatności</p>{" "}
                  <p>{document.metadata.maturity} dni</p>
                </div>
              )}
              {document.metadata.contract_value && (
                <div className="mr-6 mb-4">
                  <p className="caption mb-3">Wartość umowy</p>{" "}
                  <p>{document.metadata.contract_value}</p>
                </div>
              )}
              {document.metadata.information_for_trade && (
                <div className="mr-6 mb-4">
                  <p className="caption mb-3">Informacja dla handlu</p>{" "}
                  <p>{document.metadata.information_for_trade}</p>
                </div>
              )}
              {document.metadata.other_contract_parties &&
                document.metadata.other_contract_parties.length > 0 && (
                  <div className="mr-6 mb-4">
                    <p className="bold font-s mb-3">Inne strony umowy</p>
                    <SimpleTable
                      labels={["NIP", "Nazwa"]}
                      rows={document.metadata.other_contract_parties}
                    ></SimpleTable>
                  </div>
                )}
              {document.metadata.retro && document.metadata.retro.length > 0 && (
                <div className="mr-6 mb-4">
                  <p className="bold font-s mb-3">Retro</p>
                  <SimpleTable
                    labels={["Nazwa", "Poziom obrotu", "Wartość"]}
                    rows={document.metadata.retro}
                  ></SimpleTable>
                </div>
              )}
              {document.metadata.charge && document.metadata.charge.length > 0 && (
                <div className="mr-6 mb-4">
                  <p className="bold font-s mb-3">Opłaty</p>
                  <SimpleTable
                    labels={["Za co", "Kwota"]}
                    rows={document.metadata.charge.map(element => ({
                      ...element,
                      value: element.value + " zł",
                    }))}
                  ></SimpleTable>
                </div>
              )}
              {document.metadata.contact_person &&
                document.metadata.contact_person.length > 0 && (
                  <div className="mr-6 mb-4">
                    <p className="bold font-s mb-3">Osoba kontaktowa</p>
                    <SimpleTable
                      labels={["Imię", "Nazwisko", "Telefon", "E-mail"]}
                      rows={document.metadata.contact_person}
                    ></SimpleTable>
                  </div>
                )}
              {document.metadata.penalties &&
                document.metadata.penalties.length > 0 && (
                  <div className="mr-6 mb-4">
                    <p className="bold font-s mb-3">Kary</p>
                    <SimpleTable
                      labels={["Za co", "Kwota"]}
                      rows={document.metadata.penalties.map(penaltie => ({
                        ...penaltie,
                        value: penaltie.value + " zł",
                      }))}
                    ></SimpleTable>
                  </div>
                )}
              {document.attachments && document.attachments.length > 0 && (
                <div className="mr-6 mb-4">
                  <p className="bold font-s mb-3">Załączniki</p>
                  <div className="flex-row">
                    <p
                      className="caption"
                      style={{
                        width: "300px",
                      }}
                    >
                      Nazwa pliku
                    </p>
                    {document.attachments.filter(
                      attachment => attachment.description !== "",
                    ) > 0 && <p className="caption">Opis</p>}
                  </div>
                  {document.attachments.map(attachment => (
                    <div
                      key={attachment._id}
                      className="flex-row a-center mb-2"
                    >
                      <span
                        className="accent mr-2"
                        style={{
                          width: "300px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {attachment.originalFileName}
                      </span>
                      <span className="mr-4">{attachment.description}</span>
                      <IconButton
                        btnClick={() =>
                          downloadFile(
                            document._id,
                            attachment._id,
                            attachment.originalFileName,
                          )
                        }
                        icon="download"
                      ></IconButton>
                      <span className="mr-2"></span>
                      {attachment.extension === "pdf" && (
                        <IconButton
                          btnClick={() =>
                            openFile(
                              document._id,
                              attachment._id,
                              attachment.originalFileName,
                            )
                          }
                          icon="doc"
                        ></IconButton>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {document.assignedUsers &&
                document.assignedUsers.filter(user => user.isAccepted).length >
                  0 && (
                  <div className="mr-6">
                    <p className="bold font-s mb-3">Akceptacje</p>
                    {document.assignedUsers
                      .filter(user => user.isAccepted)
                      .map(user => (
                        <div key={user._id} className="flex-row a-center mb-2">
                          <span className="mr-4 accent">
                            {user.user.firstName + " " + user.user.lastName}
                          </span>
                          <div className="accepted-circle">
                            <SVG className="icon" src="/icons/tick.svg"></SVG>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              {document.assignedUsers &&
                document.assignedUsers.filter(user => !user.isAccepted).length >
                  0 && (
                  <div>
                    <p className="bold font-s mb-3">Nie zaakceptowali</p>
                    {document.assignedUsers
                      .filter(user => !user.isAccepted)
                      .map(user => (
                        <div
                          key={user.user._id}
                          className="flex-row a-center mb-2"
                        >
                          <span className="mr-4 caption">
                            {user.user.firstName + " " + user.user.lastName}
                          </span>
                        </div>
                      ))}
                  </div>
                )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DocumentDetails;
