import * as actionTypes from "../actions/agreements.actions";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  loading: false,
  agreements: null,
  agreementsCount: 0,
  page: 0,
  agreement: null,
  filters: {
    department: null,
    type: null,
    status: null,
    searchString: null,
    sort: null,
  },
};

const agreementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_TYPE: {
      return {
        ...cloneDeep(state),
        agreements: null,
        agreementsCount: 0,
        page: 0,
        filters: {
          ...state.filters,
          type: action.typeFilter,
        },
      };
    }
    case actionTypes.SELECT_DEPARTMENT: {
      return {
        ...cloneDeep(state),
        agreements: null,
        agreementsCount: 0,
        page: 0,
        filters: {
          ...state.filters,
          department: action.department,
        },
      };
    }
    case actionTypes.SELECT_STATUS: {
      return {
        ...cloneDeep(state),
        agreements: null,
        agreementsCount: 0,
        page: 0,
        filters: {
          ...state.filters,
          status: action.status,
        },
      };
    }
    case actionTypes.SORT: {
      return {
        ...cloneDeep(state),
        agreements: null,
        agreementsCount: 0,
        page: 0,
        filters: {
          ...state.filters,
          sort: action.sort,
        },
      };
    }
    case actionTypes.SET_SEARCH_STRING: {
      return {
        ...cloneDeep(state),
        loading: true,
        agreements: null,
        agreementsCount: 0,
        page: 0,
        filters: {
          ...state.filters,
          searchString: action.searchString,
        },
      };
    }
    case actionTypes.RESET_FILTERS: {
      return {
        ...cloneDeep(state),
        agreements: null,
        agreementsCount: 0,
        page: 0,
        filters: {
          department: null,
          type: null,
          status: null,
          searchString: null,
        },
      };
    }
    case actionTypes.CHANGE_AGREEMENTS_PAGE: {
      return {
        ...cloneDeep(state),
        page: action.page,
      };
    }
    case actionTypes.LOAD_AGREEMENTS: {
      return {
        ...cloneDeep(state),
        loading: true,
      };
    }
    case actionTypes.LOAD_AGREEMENTS_SUCCESS: {
      let newAgreements = state.agreements ? cloneDeep(state.agreements) : [];
      newAgreements.push(...action.agreements.data);

      return {
        ...cloneDeep(state),
        agreements: newAgreements,
        agreementsCount: action.agreements.count,
        page: action.page,
        loading: false,
      };
    }
    case actionTypes.REMOVE_AGREEMENT_SUCCESS: {
      let newAgreements = state.agreements ? cloneDeep(state.agreements) : [];
      const index = state.agreements.findIndex(agreement => agreement._id === action.agreementId);
      newAgreements.splice(index, 1);

      return {
        ...cloneDeep(state),
        agreements: newAgreements,
        agreementsCount: action.agreements.count - 1,
      };
    }
    case actionTypes.ADD_AGREEMENT_SUCCESS:
    case actionTypes.LOAD_AGREEMENT_SUCCESS: {
      return {
        ...cloneDeep(state),
        agreement: action.agreement,
      };
    }
    case actionTypes.APPROVE_DOCUMENT_SUCCESS: {
      const newAgreement = cloneDeep(state.agreement);
      const index = newAgreement.documents.findIndex(
        doc => doc._id === action.documentId,
      );

      newAgreement.documents[index].status = "APPROVED";
      newAgreement.status = "APPROVED";
      return {
        ...cloneDeep(state),
        agreement: newAgreement,
      };
    }
    case actionTypes.ACCEPT_DOCUMENT_SUCCESS: {
      const newAgreement = cloneDeep(state.agreement);
      const docIndex = newAgreement.documents.findIndex(
        doc => doc._id === action.documentId,
      );
      const userIndex = newAgreement.documents[
        docIndex
      ].assignedUsers.findIndex(user => user.user._id === action.userId);
      newAgreement.documents[docIndex].assignedUsers[
        userIndex
      ].isAccepted = true;

      return {
        ...cloneDeep(state),
        agreement: newAgreement,
      };
    }
    case actionTypes.CLEAR_AGREEMENT: {
      return {
        ...cloneDeep(state),
        agreement: null,
      };
    }
    case actionTypes.CLEAR_AGREEMENTS: {
      return {
        ...cloneDeep(state),
        agreements: null,
      };
    }
    default:
      return state;
  }
};

export default agreementsReducer;
