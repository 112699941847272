import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import CustomInput, { StyledInput } from "../ui/CustomInput/CustomInput";

const EditContractorDialog = props => {
  const { open, contractor, saveChanges, close } = props;

  const [data, setData] = useState({
    name: contractor.name,
    fullName: contractor.fullName,
    tin: contractor.tin
  });

  const [errors, setErrors] = useState({
    name: "",
    fullName: "",
    tin: "",
  });

  const hasError = (errors) => {
    return Object.values(errors).filter(error => error !== "").length > 0 || Object.values(data).filter(el => el === "").length > 0;
  }

  const getError = (name, value) => {
    switch (name) {
      case "name":
      case "fullName": {
        if (value === "") {
          return "Pole obowiązkowe";
        }
        return "";
      }
      case "tin": {
        const regex = /^(\d[0-9]{9})$/;
        if (!(value === "") && !regex.test(value)) {
          return "Numer NIP ma 10 cyfr";
        }
        return "";
      }

      default:
        return "";
    }
  };

  const isStringValid = (value) => {
    const regex = /^[0-9\b]+$/;
    return (value === "") | regex.test(value);
  }

  const handleInputChange = event => {
    const { value, name } = event.target;
    if (name !== 'tin' || isStringValid(value)) {
      setErrors({ ...errors, [name]: getError(name, value) });
      setData({ ...data, [name]: value });
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="edit-modal"
    >
      <div className="modal edit-modal">
        <div className="modal-title">
          <p>Kontrahent {contractor ? contractor.name : ""}</p>
        </div>
        <div className="modal-content flex-column">
          <div className='flex-row'>
          <CustomInput
            className="mr-4 mb-3"
            label="Nazwa własna"
            error={errors.name !== ""}
            helperText={errors.name}
          >
            <StyledInput
              name={"name"}
              value={data.name}
              onChange={event => handleInputChange(event)}
            />
          </CustomInput>

          <CustomInput
            className="mr-4 mb-3"
            label="Nazwa pełna"
            error={errors.fullName !== ""}
            helperText={errors.fullName}
          >
            <StyledInput
              name={"fullName"}
              value={data.fullName}
              onChange={event => handleInputChange(event)}
            />
          </CustomInput>
          <CustomInput
            className="mr-4 mb-3"
            label="NIP"
            error={errors.tin !== ""}
            helperText={errors.tin}
          >
            <StyledInput
              name={"tin"}
              value={data.tin}
              onChange={event => handleInputChange(event)}
            />
          </CustomInput>
          </div>
          <Button onClick={() => saveChanges(data)} disabled={hasError(errors)} variant="contained" color="primary">
            Zapisz
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditContractorDialog;
