import * as actionTypes from "../actions/newAgreement.actions";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  departmentId: null,
  templateId: null,
  keeper: null,
  contractor: null,
  users: null,
};

const newAgreementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEPARTMENT_ID: {
      return {
        ...cloneDeep(state),
        departmentId: action.id,
        templateId: null,
        users: null,
      };
    }
    case actionTypes.SET_TEMPLATE_ID: {
      return {
        ...cloneDeep(state),
        departmentId: state.departmentId,
        templateId: action.id,
        users: null,
      };
    }
    case actionTypes.ADD_USER: {
      let newUsers = cloneDeep(state.users);
      newUsers.push(action.user);
      return {
        ...cloneDeep(state),
        users: newUsers,
      };
    }
    case actionTypes.DELETE_USER: {
      let newUsers = cloneDeep(state.users);
      const index = newUsers.findIndex(user => user._id === action.id);
      newUsers.splice(index, 1);
      return {
        ...cloneDeep(state),
        users: newUsers,
      };
    }
    case actionTypes.SET_USERS: {
      return {
        ...cloneDeep(state),
        users: action.users,
      };
    }
    case actionTypes.SELECT_KEEPER: {
      return {
        ...cloneDeep(state),
        keeper: action.user,
      };
    }
    case actionTypes.SELECT_CONTRACTOR: {
      return {
        ...cloneDeep(state),
        contractor: action.contractor,
      };
    }
    case actionTypes.CLEAR_NEW_AGREEMENT: {
      return {
        departmentId: null,
        templateId: null,
        keeper: null,
        users: null,
      };
    }
    default:
      return state;
  }
};

export default newAgreementReducer;
