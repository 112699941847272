import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SVG from "react-inlinesvg";
import { clearSnackbar } from "../../../store/actions/ui.actions";

export default function CustomSnackbar() {
  const dispatch = useDispatch();

  const { snackbarMessage, snackbarOpen } = useSelector(state => state.ui);

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={snackbarOpen}
      onClose={handleClose}
      message={snackbarMessage}
      autoHideDuration={3000}
      action={[
        <SVG
          onClick={handleClose}
          className="icon"
          style={{ fill: "white", height: "1rem", cursos: "pointer" }}
          src={`/icons/close.svg`}
        ></SVG>,
      ]}
    />
  );
}
