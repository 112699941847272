import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  icon: {
    borderRadius: 3,
    width: 39,
    height: 39,
    backgroundColor: "#EDEEF2",
    "input:hover ~ &": {
      backgroundColor: "#E8EAEF",
    },
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 39,
      height: 39,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%237A4ADC'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
});

export const CustomFormControlLabel = withStyles(theme => ({
  root: {
    marginLeft: "-9px",
  },
  label: {
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "unset",
    fontSize: "0.8em",
  },
}))(FormControlLabel);

const CustomCheckbox = props => {
  const classes = useStyles();

  const { checked, check, label, ...rest } = props;

  return (
    <CustomFormControlLabel
      {...rest}
      value="top"
      control={
        <Checkbox
          className={classes.root}
          disableRipple
          checked={checked}
          onChange={check}
          color="default"
          checkedIcon={
            <span className={classes.icon + " " + classes.checkedIcon} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
        />
      }
      label={label}
      // labelPlacement="top"
    />
  );
};

export default CustomCheckbox;
