/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import {
  StyledTableContainer,
  StyledTableCell,
  StyledTableHead,
} from "../../helpers/table-styles";
import {
  loadAgreements,
  changePage,
  selectDepartment,
  selectType,
  setSearchString,
  selectStatus,
  resetFilters,
  setSort
} from "../../store/actions/agreements.actions";
import { useDispatch, useSelector } from "react-redux";
import { loadDepartments } from "../../store/actions/departments.actions";
import { history } from "../../helpers/history";
import { typeOptions } from "../../helpers/template-fields-list";
import CustomSelect from "../../components/ui/CustomSelect/CustomSelect";
import { agreementStatuses } from "../../helpers/agreementStatuses";
import CustomInput, {
  StyledInput,
} from "../../components/ui/CustomInput/CustomInput";
import SVG from "react-inlinesvg";

const DocumentsPage = () => {
  const dispatch = useDispatch();
  const [rowsPerPage] = React.useState(10);

  const {
    loading,
    agreements,
    agreementsCount,
    page,
    user,
    departments,
    filters,
  } = useSelector(state => ({
    loading: state.agreement.loading,
    agreements: state.agreement.agreements,
    agreementsCount: state.agreement.agreementsCount,
    page: state.agreement.page,
    user: state.auth.user,
    departments: state.departments.departments,
    filters: state.agreement.filters,
  }));

  const typesOptions = [
    { value: "all", label: "Wszystkie" },
    ...Object.entries(typeOptions).map(([key, value]) => ({
      label: value,
      value: key,
    })),
  ];

  const statusOptions = [
    { value: "all", label: "Wszystkie" },
    ...Object.entries(agreementStatuses).map(([key, value]) => ({
      label: value,
      value: key,
    })),
  ];

  const [departmentOptions, setDepartmentOptions] = useState(
    departments
      ? [
          { value: "all", label: "Wszystkie" },
          ...departments.map(department => ({
            value: department._id,
            label: department.name,
          })),
        ]
      : [{ value: "all", label: "Wszystkie" }],
  );

  const [selectedValues, setSelectedValues] = useState({
    department: filters.department ? filters.department : departmentOptions[0].value,
    type: filters.type ? filters.type : typesOptions[0].value,
    status: filters.status ? filters.status : statusOptions[0].value,
    searchString: filters.searchString ? filters.searchString : "",
  });


  useEffect(() => {
    if (!departments) {
      dispatch(loadDepartments());
    } else {
      setDepartmentOptions([
        { value: "all", label: "Wszystkie" },
        ...departments.map(department => ({
          value: department._id,
          label: department.name,
        })),
      ]);
    }
  }, [departments]);

  useEffect(() => {
    if (!agreements) {
      dispatch(loadAgreements(page, filters, rowsPerPage));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    if (newPage * rowsPerPage >= agreements.length) {
      dispatch(loadAgreements(newPage, filters, rowsPerPage));
    } else {
      dispatch(changePage(newPage));
    }
  };

  const selectDepartmentOption = event => {
    const value = event.target.value;

    dispatch(
      selectDepartment({
        ...filters,
        department: value !== "all" ? value : null,
      }),
      setSelectedValues({ ...selectedValues, department: value }),
    );
  };

  const selectTypeOption = event => {
    const value = event.target.value;
    dispatch(
      selectType({
        ...filters,
        type: value !== "all" ? value : null,
      }),
      setSelectedValues({ ...selectedValues, type: value }),
    );
  };

  const selectStatusOption = event => {
    const value = event.target.value;
    dispatch(
      selectStatus({
        ...filters,
        status: value !== "all" ? value : null,
      }),
      setSelectedValues({ ...selectedValues, status: value }),
    );
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setSelectedValues({ ...selectedValues, searchString: value });
    if (!loading) {
      dispatch(
        setSearchString({
          ...filters,
          searchString: value !== "" ? value : null,
        }),
      );
    }
  };

  const formatDate = date => {
    const formatedDate = moment(date).format("DD-MM-YYYY");
    return formatedDate;
  };

  const resetFilter = () => {
    dispatch(
      resetFilters(filters),
    );
    setSelectedValues({
      department: departmentOptions[0].value,
      type: typesOptions[0].value,
      status: statusOptions[0].value,
      searchString: "",
    })
  }

  return (
    <div className="table-contaier">
      <div className="flex-row a-end j-between mb-4">
        <div className="flex-row a-end">
          <CustomSelect
            label="Dział"
            changeOption={selectDepartmentOption}
            selectedOption={selectedValues.department}
            options={departmentOptions}
            theme="dark"
          ></CustomSelect>
          <CustomSelect
            label="Typ"
            className="ml-4"
            changeOption={selectTypeOption}
            selectedOption={selectedValues.type}
            options={typesOptions}
            theme="dark"
          ></CustomSelect>
          <CustomSelect
            label="Status"
            className="ml-4"
            changeOption={selectStatusOption}
            selectedOption={selectedValues.status}
            options={statusOptions}
            theme="dark"
          ></CustomSelect>
          <CustomInput className="ml-4" label="Szukaj kontrahenta">
            <StyledInput
              name={"name"}
              value={selectedValues.searchString}
              onChange={event => handleInputChange(event)}
              theme="dark"
            />
          </CustomInput>
          <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => resetFilter()}
              className="ml-4 "
            >
              Resetuj filtry
            </Button>
        </div>
        <Link to="/new-agreement">
          {user && user.isAdmin && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="ml-4 "
            >
              Dodaj nową umowę
            </Button>
          )}
        </Link>
      </div>
      {agreements && (
        <React.Fragment>
          <StyledTableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>
                    Nazwa klienta
                    {filters.sort !== 'contractor.name' && filters.sort !== '-contractor.name' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort.svg" onClick={() => dispatch(setSort({...filters, sort: "contractor.name"}))} />}
                    {filters.sort === 'contractor.name' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort-desc.svg" onClick={() => dispatch(setSort({...filters, sort: "-contractor.name"}))} />}
                    {filters.sort === '-contractor.name' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort-asc.svg" onClick={() => dispatch(setSort({...filters, sort: "contractor.name"}))} />}
                  </StyledTableCell>
                  <StyledTableCell>NIP</StyledTableCell>
                  <StyledTableCell>Data początkowa
                    {filters.sort !== 'mainDocument.startDate' && filters.sort !== '-mainDocument.startDate' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort.svg" onClick={() => dispatch(setSort({...filters, sort: "mainDocument.startDate"}))} />}
                    {filters.sort === 'mainDocument.startDate' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort-desc.svg" onClick={() => dispatch(setSort({...filters, sort: "-mainDocument.startDate"}))} />}
                    {filters.sort === '-mainDocument.startDate' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort-asc.svg" onClick={() => dispatch(setSort({...filters, sort: "mainDocument.startDate"}))} />}
                  </StyledTableCell>
                  <StyledTableCell>Data końcowa
                    {filters.sort !== 'mainDocument.endDate' && filters.sort !== '-mainDocument.endDate' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort.svg" onClick={() => dispatch(setSort({...filters, sort: "mainDocument.endDate"}))} />}
                    {filters.sort === 'mainDocument.endDate' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort-desc.svg" onClick={() => dispatch(setSort({...filters, sort: "-mainDocument.endDate"}))} />}
                    {filters.sort === '-mainDocument.endDate' && <SVG className="icon" style={{"height": "10px", "cursor": "pointer"}} src="/icons/sort-asc.svg" onClick={() => dispatch(setSort({...filters, sort: "mainDocument.endDate"}))} />}
                  </StyledTableCell>
                  <StyledTableCell>Typ</StyledTableCell>
                  <StyledTableCell>Dział</StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {agreements
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow
                      key={row._id}
                      hover
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/documents/${row._id}`)}
                    >
                      <StyledTableCell>
                        <div>
                          <p className="bold">{row.clientName}</p>
                          <p>{row.contractor.fullName}</p>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{row.contractor.tin}</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(row.startDate)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.endDate
                          ? formatDate(row.endDate)
                          : "Umowa na czas nieokreślony"}
                      </StyledTableCell>
                      <StyledTableCell>{typeOptions[row.type]}</StyledTableCell>
                      <StyledTableCell>{row.department}</StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPage}
              count={agreementsCount}
              rowsPerPageOptions={[]}
              nextIconButtonProps={{
                disabled:
                  loading || page + 1 >= Math.ceil(agreementsCount / rowsPerPage),
              }}
              backIconButtonProps={{ disabled: loading || page === 0 }}
              page={page}
              onChangePage={handleChangePage}
            />
          </StyledTableContainer>
        </React.Fragment>
      )}
    </div>
  );
};

export default DocumentsPage;
