import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import CustomInput, { StyledInput } from "../ui/CustomInput/CustomInput";

const DepartmentModal = props => {
  const { open, close, click, btnLabel, title } = props;
  const [name, setName] = useState("");

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleClick = () => {
    click(name);
    setName("");
  };

  return (
    <Modal open={open} onClose={close}>
      <div className="modal edit-modal">
        <div className="modal-title">
          <p>{title}</p>
        </div>
        <div className="modal-content">
          <CustomInput label="Nazwa">
            <StyledInput value={name} onChange={handleNameChange} />
          </CustomInput>
          <Button onClick={handleClick} variant="contained" color="primary">
            {btnLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DepartmentModal;
