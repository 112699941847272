import React from "react";
import Button from "@material-ui/core/Button";
import "./LoginPage.scss";
import { useDispatch } from "react-redux";
import { getPartnerInfo } from "../../store/actions/authentication.actions";

const LoginPage = () => {
  const dispatch = useDispatch();

  return (
    <div className="panel login-panel">
      <p className="login-title">Logowanie</p>
      <Button
        onClick={() => dispatch(getPartnerInfo(window.location.hostname))}
        variant="contained"
        color="primary"
        className="mt-6"
      >
        Zaloguj się
      </Button>
    </div>
  );
};

export default LoginPage;
