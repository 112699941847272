import React, { useEffect } from "react";
import MetadataStep from "../../components/AddAgreementSteps/MetadataStep";
import { useSelector, useDispatch } from "react-redux";
import {
  loadAgreement,
  addDocument,
  confirmDocument,
} from "../../store/actions/agreements.actions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import AttachmentStep from "../../components/AddAgreementSteps/AttachmentStep";
import { history } from "../../helpers/history";

const AddDocumentPage = props => {
  const dispatch = useDispatch();
  const { agreement, newDocumentId } = useSelector(state => ({
    agreement: state.agreement.agreement,
    newDocumentId: state.newDocument.documentId,
  }));

  function getSteps() {
    return ["Uzupełnij dane dokumentu", "Dodaj załączniki"];
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (!agreement || agreement._id !== params.agreementId) {
      dispatch(loadAgreement(params.agreementId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement]);

  const getStepContent = step => {
    const metadata = {
      type: agreement.mainDocument.type,
      contract_start_date: agreement.mainDocument.startDate,
      contract_end_date: agreement.mainDocument.endDate,
      ...agreement.mainDocument.metadata,
    };

    switch (step) {
      case 0:
        return (
          <MetadataStep
            next={metadata => handleAddDoc(metadata)}
            initialMetadata={metadata}
            initialContractor={agreement.contractor}
          ></MetadataStep>
        );
      case 1:
        return <AttachmentStep add={handleConfirm}></AttachmentStep>;
      default:
        return "Unknown step";
    }
  };
  
  const handleAddDoc = metadata => {
    const { contract_start_date, contract_end_date, type, ...rest } = metadata;

    const document = {
      type: type,
      metadata: rest,
      startDate: contract_start_date,
      endDate: contract_end_date,
    };

    dispatch(addDocument(agreement._id, document));
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleConfirm = () => {
    if (agreement && agreement.documents[0]) {
      dispatch(confirmDocument(newDocumentId, agreement._id));
      history.push(`/documents/${agreement._id}`);
    }
  };

  return (
    <div className="panel">
      {agreement && (
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>{getStepContent(index)}</StepContent>
            </Step>
          ))}
        </Stepper>
      )}
    </div>
  );
};

export default AddDocumentPage;
