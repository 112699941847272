import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { agreementStatuses } from "../../helpers/agreementStatuses";
import IconButton from "../../components/ui/IconButton/IconButton";
import ConfirmModal from "../../components/ui/ConfirmModal/ConfirmModal";
import { useDispatch } from "react-redux";
import { removeAgreement } from "../../store/actions/agreements.actions";


const AgreementHeader = props => {
  const { agreement, user } = props;

  const keeper = agreement.assignedUsers.find(user => user.isKeeper);
  const [confirmRemoveModalOpen, setConfirmRemoveModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(removeAgreement(agreement._id));
    setConfirmRemoveModalOpen(false)
  };

  return (
    <div className="panel flex-row j-between p-4">
      <div className="name mr-5">
        <p className="bold mb-3">{agreement.contractor.name}</p>
        <p>{agreement.contractor.fullName}</p>
      </div>
      <div className="mr-5">
        <p className="caption mb-3">NIP</p> <p>{agreement.contractor.tin}</p>
      </div>
      <div className="mr-5">
        <p className="caption mb-3">Dział</p> <p>{agreement.department}</p>
      </div>
      <div className="mr-5">
        <p className="caption mb-3">Status</p>{" "}
        <p>{agreementStatuses[agreement.status]}</p>
      </div>
      <div className="mr-5">
        <p className="caption mb-3">Opiekun</p>{" "}
        <p>
          {keeper
            ? keeper.user.firstName + " " + keeper.user.lastName
            : "Brak opiekuna"}
        </p>
      </div>
      <div>
        {user && user.isAdmin && (
          <div className="flex-row a-center">
            <Link to={`/new-document/${agreement._id}`}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="mr-3"
              >
                Dodaj dokument
              </Button>
            </Link>
            <Link to={`/edit-agreement/${agreement._id}`}>
              <IconButton
                style={{ marginRight: "1rem" }}
                icon="pencil"
              ></IconButton>
            </Link>
            <IconButton
              onClick={() => setConfirmRemoveModalOpen(true)}
              icon="trash"
              className="icon-button primary"
            ></IconButton>
          </div>
        )}
      </div>
      <ConfirmModal
        title="Czy na pewno chcesz usunąć dokument?"
        open={confirmRemoveModalOpen}
        close={() => setConfirmRemoveModalOpen(false)}
        confirm={handleDelete}
      ></ConfirmModal>
    </div>
  );
};

export default AgreementHeader;
