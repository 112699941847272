import { handleError } from "../../helpers/handleError";
import axios from "axios";
import { history } from "../../helpers/history";
import { clearNewDocument } from "./newDocument.actions";

export const CHANGE_AGREEMENTS_PAGE = "CHANGE_AGREEMENTS_PAGE";

export const LOAD_AGREEMENTS = "LOAD_AGREEMENTS";
export const LOAD_AGREEMENTS_SUCCESS = "LOAD_AGREEMENTS_SUCCESS";
export const LOAD_AGREEMENTS_FAILURE = "LOAD_AGREEMENTS_FAILURE";

export const LOAD_AGREEMENT = "LOAD_AGREEMENT";
export const LOAD_AGREEMENT_SUCCESS = "LOAD_AGREEMENT_SUCCESS";
export const LOAD_AGREEMENT_FAILURE = "LOAD_AGREEMENT_FAILURE";

export const ADD_AGREEMENT = "ADD_AGREEMENT";
export const ADD_AGREEMENT_SUCCESS = "ADD_AGREEMENT_SUCCESS";
export const ADD_AGREEMENT_FAILURE = "ADD_AGREEMENT_FAILURE";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";

export const CONFIRM_DOCUMENT = "CONFIRM_DOCUMENT";
export const CONFIRM_DOCUMENT_SUCCESS = "CONFIRM_DOCUMENT_SUCCESS";
export const CONFIRM_DOCUMENT_FAILURE = "CONFIRM_DOCUMENT_FAILURE";

export const APPROVE_DOCUMENT = "APPROVE_DOCUMENT";
export const APPROVE_DOCUMENT_SUCCESS = "APPROVE_DOCUMENT_SUCCESS";
export const APPROVE_DOCUMENT_FAILURE = "APPROVE_DOCUMENT_FAILURE";

export const ACCEPT_DOCUMENT = "ACCEPT_DOCUMENT";
export const ACCEPT_DOCUMENT_SUCCESS = "ACCEPT_DOCUMENT_SUCCESS";
export const ACCEPT_DOCUMENT_FAILURE = "ACCEPT_DOCUMENT_FAILURE";

export const EDIT_AGREEMENT = "EDIT_AGREEMENT";
export const EDIT_AGREEMENT_SUCCESS = "EDIT_AGREEMENT_SUCCESS";
export const EDIT_AGREEMENT_FAILURE = "EDIT_AGREEMENT_FAILURE";

export const CLEAR_AGREEMENT = "CLEAR_AGREEMENT";
export const CLEAR_AGREEMENTS = "CLEAR_AGREEMENTS";

export const SELECT_DEPARTMENT = "SELECT_DEPARTMENT";
export const SELECT_TYPE = "SELECT_TYPE";
export const SELECT_STATUS = "SELECT_STATUS";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const RESET_FILTERS = "RESET_FILTERS";

export const SORT = "SORT";
export const SORT_BY_END_DATE = "SORT_BY_END_DATE";

export const REMOVE_AGREEMENT = "REMOVE_AGREEMENT";
export const REMOVE_AGREEMENT_SUCCESS = "REMOVE_AGREEMENT_SUCCESS";
export const REMOVE_AGREEMENT_FAILURE = "REMOVE_AGREEMENT_FAILURE";


const baseUrl = process.env.REACT_APP_API_URL;

export const changePage = page => {
  return {
    type: CHANGE_AGREEMENTS_PAGE,
    page,
  };
};

export const addAgreement = (agreement, document) => {
  return dispatch => {
    dispatch({ type: ADD_AGREEMENT });
    axios
      .post(`${baseUrl}api/contracts`, agreement)
      .then(response => {
        dispatch(addAgreementSuccess(response.data, document));
      })
      .catch(error => dispatch(addAgreementFailure(error)));
  };
};

export const addAgreementSuccess = (agreement, document) => {
  return dispatch => {
    dispatch({ type: ADD_AGREEMENT_SUCCESS, agreement });
    dispatch(addDocument(agreement._id, document));
  };
};

export const addAgreementFailure = error => {
  handleError(error);
  return {
    type: ADD_AGREEMENT_FAILURE,
    error: error,
  };
};

export const addDocument = (id, document) => {
  return dispatch => {
    dispatch({ type: ADD_DOCUMENT });
    axios
      .post(`${baseUrl}api/documents`, { contractId: id, ...document })
      .then(response => {
        dispatch(addDocumentSuccess(response.data));
      })
      .catch(error => dispatch(addDocumentFailure(error)));
  };
};

export const addDocumentSuccess = document => {
  return {
    type: ADD_DOCUMENT_SUCCESS,
    document,
  };
};

export const addDocumentFailure = error => {
  handleError(error);
  return {
    type: ADD_AGREEMENT_FAILURE,
    error: error,
  };
};

export const loadAgreements = (page, filters, rowsPerPage = 10) => {
  return dispatch => {
    dispatch({ type: LOAD_AGREEMENTS });
    axios
      .get(
        `${baseUrl}api/contracts?skip=${page * rowsPerPage}${
          filters.department ? `&departmentId=${filters.department}` : ""
        }${filters.type ? `&type=${filters.type}` : ""}${
          filters.status ? `&status=${filters.status}` : ""
        }${
          filters.searchString ? `&searchString=${filters.searchString}` : ""
        }${
          filters.sort ? `&sort=${filters.sort}` : ""
        }`,
      )
      .then(response => {
        dispatch(loadAgreementsSuccess(response.data, page));
      })
      .catch(error => dispatch(loadAgreementsFailure(error)));
  };
};

export const loadAgreementsSuccess = (agreements, page) => {
  return {
    type: LOAD_AGREEMENTS_SUCCESS,
    agreements,
    page,
  };
};

export const loadAgreementsFailure = error => {
  handleError(error);
  return {
    type: LOAD_AGREEMENTS_FAILURE,
    error: error,
  };
};

export const loadAgreement = id => {
  return dispatch => {
    dispatch({ type: LOAD_AGREEMENT });
    axios
      .get(`${baseUrl}api/contracts/${id}`)
      .then(response => {
        dispatch(loadAgreementSuccess(response.data));
      })
      .catch(error => dispatch(loadAgreementFailure(error)));
  };
};

export const loadAgreementSuccess = agreement => {
  return {
    type: LOAD_AGREEMENT_SUCCESS,
    agreement,
  };
};

export const loadAgreementFailure = error => {
  handleError(error);
  return {
    type: LOAD_AGREEMENT_FAILURE,
    error: error,
  };
};

export const confirmDocument = (documentId, agreementId) => {
  return dispatch => {
    dispatch({ type: CONFIRM_DOCUMENT });
    axios
      .put(`${baseUrl}api/documents/${documentId}/confirm`)
      .then(() => {
        dispatch(confirmDocumentSuccess(agreementId));
      })
      .catch(error => dispatch(confirmDocumentFailure(error)));
  };
};

export const confirmDocumentSuccess = agreementId => {
  history.push(`/documents/${agreementId}`);
  return dispatch => {
    dispatch({ type: CONFIRM_DOCUMENT_SUCCESS });
    dispatch(loadAgreement(agreementId));
    dispatch(clearAgreements());
    dispatch(clearNewDocument());
  };
};

export const confirmDocumentFailure = error => {
  return dispatch => {
    dispatch({ type: CONFIRM_DOCUMENT_FAILURE,  error: error });
    dispatch(clearNewDocument());
  };
};

export const approveDocument = documentId => {
  return dispatch => {
    dispatch({ type: APPROVE_DOCUMENT });
    axios
      .put(`${baseUrl}api/documents/${documentId}/approve`)
      .then(() => {
        dispatch(approveDocumentSuccess(documentId));
      })
      .catch(error => dispatch(approveDocumentFailure(error)));
  };
};

export const approveDocumentSuccess = documentId => {
  return {
    type: APPROVE_DOCUMENT_SUCCESS,
    documentId,
  };
};

export const approveDocumentFailure = error => {
  handleError(error);
  return {
    type: APPROVE_DOCUMENT_FAILURE,
    error: error,
  };
};

export const acceptDocument = (documentId, userId) => {
  return dispatch => {
    dispatch({ type: ACCEPT_DOCUMENT });
    axios
      .put(`${baseUrl}api/documents/${documentId}/accept`)
      .then(() => {
        dispatch(acceptDocumentSuccess(documentId, userId));
      })
      .catch(error => dispatch(acceptDocumentFailure(error)));
  };
};

export const acceptDocumentSuccess = (documentId, userId) => {
  return {
    type: ACCEPT_DOCUMENT_SUCCESS,
    documentId,
    userId,
  };
};

export const acceptDocumentFailure = error => {
  handleError(error);
  return {
    type: ACCEPT_DOCUMENT_FAILURE,
    error: error,
  };
};

export const editAgreement = (contractId, contractorId, assignedUsers) => {
  return dispatch => {
    dispatch({ type: EDIT_AGREEMENT });
    axios
      .put(`${baseUrl}api/contracts/${contractId}`, {
        contractorId,
        assignedUsers,
      })
      .then(() => {
        dispatch(editAgreementSuccess(contractId));
      })
      .catch(error => dispatch(editAgreementFailure(error)));
  };
};

export const editAgreementSuccess = contractId => {
  return dispatch => {
    dispatch({ type: EDIT_AGREEMENT_SUCCESS });
    dispatch(loadAgreement(contractId));
  };
};

export const editAgreementFailure = error => {
  handleError(error);
  return {
    type: EDIT_AGREEMENT_FAILURE,
    error: error,
  };
};

export const clearAgreement = () => {
  return {
    type: CLEAR_AGREEMENT,
  };
};

export const clearAgreements = () => {
  return {
    type: CLEAR_AGREEMENTS,
  };
};

export const selectDepartment = filters => {
  return dispatch => {
    dispatch({ type: SELECT_DEPARTMENT, department: filters.department });
    dispatch(loadAgreements(0, filters));
  };
};

export const selectType = filters => {
  return dispatch => {
    dispatch({ type: SELECT_TYPE, typeFilter: filters.type });
    dispatch(loadAgreements(0, filters));
  };
};

export const selectStatus = filters => {
  return dispatch => {
    dispatch({ type: SELECT_STATUS, status: filters.status });
    dispatch(loadAgreements(0, filters));
  };
};

export const setSearchString = filters => {
  return dispatch => {
    dispatch({ type: SET_SEARCH_STRING, searchString: filters.searchString });
    dispatch(loadAgreements(0, filters));
  };
};

export const setSort = filters => {
  return dispatch => {
    dispatch({ type: SORT, sort: filters.sort });
    dispatch(loadAgreements(0, filters));
  };
};

export const resetFilters = () => {
  return dispatch => {
    dispatch({ type: RESET_FILTERS });
    dispatch(
      loadAgreements(0, {
        department: null,
        type: null,
        status: null,
        searchString: null,
        sort: null,
      }),
    );
  };
};

export const removeAgreement = agreementId => {
  return dispatch => {
    dispatch({ type: ACCEPT_DOCUMENT });
    axios
      .delete(`${baseUrl}api/contracts/${agreementId}`)
      .then(() => {
        dispatch(removeAgreementSuccess(agreementId));
      })
      .catch(error => dispatch(removeAgreementFailure(error)));
  };
};

export const removeAgreementSuccess = agreementId => {
  history.push("/documents");
  return { type: REMOVE_AGREEMENT_SUCCESS, agreementId };
};

export const removeAgreementFailure = error => {
  handleError(error);
  return {
    type: REMOVE_AGREEMENT_FAILURE,
    error: error,
  };
};
