import React from "react";
import CustomSelect from "../ui/CustomSelect/CustomSelect";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import "./EditUserDialog.scss";

const roles = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];

const EditUserDialog = props => {
  const { open, user, changeRole, saveChanges, close } = props;

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="edit-modal"
    >
      <div className="modal edit-modal">
        <div className="modal-title">
          <p>
            Edycja użytkownika -{" "}
            {user ? user.firstName + " " + user.lastName : ""}
          </p>
        </div>
        <div className="modal-content">
          <CustomSelect
            label="Role"
            changeOption={changeRole}
            selectedOption={user ? (user.isAdmin ? "admin" : "user") : ""}
            options={roles}
          ></CustomSelect>
          <Button onClick={saveChanges} variant="contained" color="primary">
            Zapisz
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserDialog;
