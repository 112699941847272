import { handleError } from "../../helpers/handleError";
import axios from "axios";

export const LOAD_DEPARTMENTS = "LOAD_DEPARTMENTS";
export const LOAD_DEPARTMENTS_SUCCESS = "LOAD_DEPARTMENTS_SUCCESS";
export const LOAD_DEPARTMENTS_FAILURE = "LOAD_DEPARTMENTS_FAILURE";

export const LOAD_DEPARTMENT_USERS = "LOAD_DEPARTMENT_USERS";
export const LOAD_DEPARTMENT_USERS_SUCCESS = "LOAD_DEPARTMENT_USERS_SUCCESS";
export const LOAD_DEPARTMENT_USERS_FAILURE = "LOAD_DEPARTMENT_USERS_FAILURE";

export const REMOVE_DEPARTMENT_USER = "REMOVE_DEPARTMENT_USER";
export const REMOVE_DEPARTMENT_USER_SUCCESS = "REMOVE_DEPARTMENT_USER_SUCCESS";
export const REMOVE_DEPARTMENT_USER_FAILURE = "REMOVE_DEPARTMENT_USER_FAILURE";

export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_FAILURE = "ADD_DEPARTMENT_FAILURE";

export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT";
export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_FAILURE = "EDIT_DEPARTMENT_FAILURE";

export const ADD_USER_TO_DEPARTMENT = "ADD_USER_TO_DEPARTMENT";
export const ADD_USER_TO_DEPARTMENT_SUCCESS = "ADD_USER_TO_DEPARTMENT_SUCCESS";
export const ADD_USER_TO_DEPARTMENT_FAILURE = "ADD_USER_TO_DEPARTMENT_FAILURE";

const baseUrl = process.env.REACT_APP_API_URL;

export const loadDepartments = () => {
  return dispatch => {
    dispatch({ type: LOAD_DEPARTMENTS });
    axios
      .get(`${baseUrl}api/departments`)
      .then(response => {
        dispatch(loadDepartmentsSuccess(response.data));
      })
      .catch(error => dispatch(loadDepartmentsFailure(error)));
  };
};

export const loadDepartmentsSuccess = departments => {
  return dispatch => {
    dispatch({ type: LOAD_DEPARTMENTS_SUCCESS, departments });
    if (departments[0]) {
      dispatch(loadDepartmentUsers(departments[0]._id));
    }
  };
};

export const loadDepartmentsFailure = error => {
  handleError(error);
  return {
    type: LOAD_DEPARTMENTS_FAILURE,
    error: error,
  };
};

export const loadDepartmentUsers = id => {
  return dispatch => {
    dispatch({ type: LOAD_DEPARTMENT_USERS, id });
    axios
      .get(`${baseUrl}api/departments/${id}/users`)
      .then(response => {
        dispatch(loadDepartmentUsersSuccess(response.data));
      })
      .catch(error => dispatch(loadDepartmentUsersFailure(error)));
  };
};

export const loadDepartmentUsersSuccess = users => {
  return {
    type: LOAD_DEPARTMENT_USERS_SUCCESS,
    users,
  };
};

export const loadDepartmentUsersFailure = error => {
  handleError(error);
  return {
    type: LOAD_DEPARTMENT_USERS_FAILURE,
    error: error,
  };
};

export const removeDepartmentUser = (departmentId, userId) => {
  return dispatch => {
    dispatch({ type: REMOVE_DEPARTMENT_USER });
    axios
      .delete(`${baseUrl}api/departments/${departmentId}/users/${userId}`)
      .then(response => {
        dispatch(removeDepartmentUserSuccess(departmentId, userId));
      })
      .catch(error => dispatch(removeDepartmentUserFailure(error)));
  };
};

export const removeDepartmentUserSuccess = (departmentId, userId) => {
  return {
    type: REMOVE_DEPARTMENT_USER_SUCCESS,
    departmentId,
    userId,
  };
};

export const removeDepartmentUserFailure = error => {
  handleError(error);
  return {
    type: REMOVE_DEPARTMENT_USER_FAILURE,
    error: error,
  };
};

export const addDepartment = name => {
  return dispatch => {
    dispatch({ type: ADD_DEPARTMENT });
    axios
      .post(`${baseUrl}api/departments`, { name })
      .then(response => {
        dispatch(addDepartmentSuccess(response.data));
      })
      .catch(error => dispatch(addDepartmentFailure(error)));
  };
};

export const addDepartmentSuccess = department => {
  return {
    type: ADD_DEPARTMENT_SUCCESS,
    department,
  };
};

export const addDepartmentFailure = error => {
  handleError(error);
  return {
    type: ADD_DEPARTMENT_FAILURE,
    error: error,
  };
};

export const addUserToDepartment = (departmentId, user) => {
  return dispatch => {
    dispatch({ type: ADD_USER_TO_DEPARTMENT });
    axios
      .post(`${baseUrl}api/departments/${departmentId}/users/${user._id}`, null)
      .then(response => {
        dispatch(addUserToDepartmentSuccess(user));
      })
      .catch(error => dispatch(addUserToDepartmentFailure(error)));
  };
};

export const addUserToDepartmentSuccess = user => {
  return dispatch => {
    dispatch({ type: ADD_USER_TO_DEPARTMENT_SUCCESS, user });
  };
};

export const addUserToDepartmentFailure = error => {
  handleError(error);
  return {
    type: ADD_USER_TO_DEPARTMENT_FAILURE,
    error: error,
  };
};

export const editDepartment = (departmentId, name) => {
  return dispatch => {
    dispatch({ type: EDIT_DEPARTMENT });
    axios
      .put(`${baseUrl}api/departments/${departmentId}`, { name })
      .then(response => {
        dispatch(editDepartmentSuccess(departmentId, name));
      })
      .catch(error => dispatch(editDepartmentFailure(error)));
  };
};

export const editDepartmentSuccess = (departmentId, name) => {
  return {
    type: EDIT_DEPARTMENT_SUCCESS,
    departmentId,
    name,
  };
};

export const editDepartmentFailure = error => {
  handleError(error);
  return {
    type: EDIT_DEPARTMENT_FAILURE,
    error: error,
  };
};
