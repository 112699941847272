import { loadDepartmentUsers } from "./departments.actions";
import { loadDepartmentTemplates } from "./templates.actions";
import { changePage } from "./users.actions";
export const SET_DEPARTMENT_ID = "SET_DEPARTMENT_ID";
export const SET_TEMPLATE_ID = "SET_TEMPLATE_ID";

export const ADD_USER = "ADD_USER";
export const SET_USERS = "SET_USERS";
export const DELETE_USER = "DELETE_USER";

export const SELECT_KEEPER = "SELECT_KEEPER";
export const SELECT_CONTRACTOR = "SELECT_CONTRACTOR";

export const CLEAR_NEW_AGREEMENT = "CLEAR_NEW_AGREEMENT";

export const setDepartmentId = id => {
  return dispatch => {
    dispatch({ type: SET_DEPARTMENT_ID, id });
    dispatch(loadDepartmentUsers(id));
    dispatch(loadDepartmentTemplates(id));
  };
};

export const setTemplateId = id => {
  return {
    type: SET_TEMPLATE_ID,
    id,
  };
};

export const addUser = user => {
  return {
    type: ADD_USER,
    user,
  };
};

export const deleteUser = id => {
  return {
    type: DELETE_USER,
    id,
  };
};

export const setUsers = users => {
  return dispatch => {
    dispatch({ type: SET_USERS, users });
  };
};

export const selectKeeper = user => {
  return dispatch => {
    dispatch({
      type: SELECT_KEEPER,
      user,
    });
    dispatch(changePage(0));
  };
};

export const selectContractor = contractor => {
  return dispatch => {
    dispatch({
      type: SELECT_CONTRACTOR,
      contractor,
    });
  };
};

export const clearNewAgreement = () => {
  return {
    type: CLEAR_NEW_AGREEMENT,
  };
};
