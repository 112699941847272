/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { loadDepartments } from "../../store/actions/departments.actions";
import CustomSelect from "../../components/ui/CustomSelect/CustomSelect";
import IconButton from "../../components/ui/IconButton/IconButton";
import {
  StyledTableContainer,
  StyledTableCell,
  StyledTableHead,
} from "../../helpers/table-styles";
import EditUserDialog from "../../components/EditUserDialog/EditUserDialog";
import {
  loadUsers,
  changePage,
  updateUser,
  selectDepartment,
} from "../../store/actions/users.actions";
import { useDispatch, useSelector } from "react-redux";

const UsersPage = () => {
  const [rowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedUser, setUser] = useState(null);

  const dispatch = useDispatch();

  const { loading, users, count, page, departments, filters } = useSelector(
    state => ({
      loading: state.users.loading,
      users: state.users.users,
      count: state.users.count,
      page: state.users.page,
      departments: state.departments.departments,
      filters: state.users.filters,
    }),
  );

  const [departmentOptions, setDepartmentOptions] = useState(
    departments
      ? [
          { value: "all", label: "Wszystkie" },
          ...departments.map(department => ({
            value: department._id,
            label: department.name,
          })),
        ]
      : [{ value: "all", label: "Wszystkie" }],
  );

  const [selectedDepartment, setSelectedDepartment] = useState(
    departmentOptions[0].value,
  );

  useEffect(() => {
    dispatch(
      selectDepartment({
        ...filters,
        department: null,
      }),
    );
  }, []);

  useEffect(() => {
    if (!departments) {
      dispatch(loadDepartments());
    } else {
      setDepartmentOptions([
        { value: "all", label: "Wszystkie" },
        ...departments.map(department => ({
          value: department._id,
          label: department.name,
        })),
      ]);
    }
  }, [departments]);

  const handleChangePage = (event, newPage) => {
    if (newPage * 10 >= users.length) {
      dispatch(loadUsers(newPage, filters));
    } else {
      dispatch(changePage(newPage));
    }
  };

  const selectDepartmentOption = event => {
    const value = event.target.value;

    dispatch(
      selectDepartment({
        ...filters,
        department: value !== "all" ? value : null,
      }),
      setSelectedDepartment(value),
    );
  };

  const handleEdit = user => {
    setOpen(true);
    setUser({ ...user });
  };

  const handleClose = value => {
    setOpen(false);
  };

  const handleRoleChange = event => {
    setUser({ ...selectedUser, isAdmin: event.target.value === "admin" });
  };

  const handleSaveChanges = () => {
    dispatch(updateUser(selectedUser._id, selectedUser.isAdmin));
    setOpen(false);
  };

  return (
    <div className="table-contaier">
      <CustomSelect
        label="Dział"
        className="mb-4"
        changeOption={selectDepartmentOption}
        selectedOption={selectedDepartment}
        options={departmentOptions}
        theme="dark"
      ></CustomSelect>
      {count > 0 && (
        <React.Fragment>
          <StyledTableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>Imię i nazwisko</StyledTableCell>
                  <StyledTableCell>E-mail</StyledTableCell>
                  <StyledTableCell>Numer telefonu</StyledTableCell>
                  <StyledTableCell>Rola</StyledTableCell>
                  <StyledTableCell>Działy</StyledTableCell>
                  <StyledTableCell align="right">Edycja</StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow key={row._id}>
                      <StyledTableCell>
                        {row.firstName + " " + row.lastName}
                      </StyledTableCell>
                      <StyledTableCell>{row.emailAddress}</StyledTableCell>
                      <StyledTableCell>{row.phoneNumber}</StyledTableCell>
                      <StyledTableCell>
                        {row.isAdmin ? "Admin" : "User"}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.departments
                          ? row.departments
                              .map(department => department.name)
                              .join(", ")
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton
                          btnClick={() => handleEdit(row)}
                          style={{ marginLeft: "auto" }}
                          icon="pencil"
                        ></IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPage}
              count={count}
              rowsPerPageOptions={[]}
              page={page}
              nextIconButtonProps={{
                disabled: loading || page + 1 >= Math.ceil(count / rowsPerPage),
              }}
              backIconButtonProps={{ disabled: loading || page === 0 }}
              onChangePage={handleChangePage}
            />
          </StyledTableContainer>
          <EditUserDialog
            open={open}
            user={selectedUser}
            changeRole={handleRoleChange}
            saveChanges={handleSaveChanges}
            close={handleClose}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default UsersPage;
