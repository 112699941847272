import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { StyledInput, useStyles } from "../CustomInput/CustomInput";

const CustomSelect = props => {
  const classes = useStyles();

  const { label, selectedOption, changeOption, options, star, ...rest } = props;

  return (
    <React.Fragment>
      <FormControl className={classes.formControl} {...rest}>
        <InputLabel id="customized-select-label">
          {label} {star && <span style={{ color: "red" }}>*</span>}
        </InputLabel>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={selectedOption}
          onChange={changeOption}
          input={<StyledInput theme={rest.theme} />}
        >
          {options.map((option, id) => (
            <MenuItem key={id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default CustomSelect;
