/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAgreement, clearAgreement } from "../../store/actions/agreements.actions";
import AgreementHeader from "../../components/AgreementDetails/AgreementHeader";
import DocumentDetails from "../../components/AgreementDetails/DocumentDetails";
import Comments from "../../components/Comments/Comments";

const AgreementPage = props => {
  const dispatch = useDispatch();

  const { agreement, user } = useSelector(state => ({
    agreement: state.agreement.agreement,
    user: state.auth.user,
  }));

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (!agreement || agreement._id !== params.documentId) {
      dispatch(loadAgreement(params.documentId));
    }
  }, [agreement]);

  useEffect(() => {
    return () => {
      dispatch(clearAgreement());
    };
  }, []);

  return (
    <>
      {agreement && agreement.documents && (
        <>
          <AgreementHeader agreement={agreement} user={user}></AgreementHeader>
          <div className="mt-4">
            {agreement.documents.map((document, id) => (
              <DocumentDetails
                document={document}
                isMain={document._id === agreement.mainDocument._id}
                key={id}
                id={agreement.documents.length - id}
                open={id === 0}
              ></DocumentDetails>
            ))}
          </div>
          <Comments contractId={agreement._id}></Comments>
        </>
      )}
    </>
  );
};

export default AgreementPage;
