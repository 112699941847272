import { handleError } from "../../helpers/handleError";
import { showSnackbar } from "./ui.actions";
import axios from "axios";

export const LOAD_DEPARTMENT_TEMPLATES = "LOAD_DEPARTMENT_TEMPLATES";
export const LOAD_DEPARTMENT_TEMPLATES_SUCCESS =
  "LOAD_DEPARTMENT_TEMPLATES_SUCCESS";
export const LOAD_DEPARTMENT_TEMPLATES_FAILURE =
  "LOAD_DEPARTMENT_TEMPLATES_FAILURE";

export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAILURE = "EDIT_TEMPLATE_FAILURE";

export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

const baseUrl = process.env.REACT_APP_API_URL;

export const loadDepartmentTemplates = departmentID => {
  return dispatch => {
    dispatch({ type: LOAD_DEPARTMENT_TEMPLATES });
    axios
      .get(`${baseUrl}api/departments/${departmentID}/contract-templates`)
      .then(response => {
        dispatch(loadDepartmentTemplatesSuccess(response.data));
      })
      .catch(error => dispatch(loadDepartmentTemplatesFailure(error)));
  };
};

export const loadDepartmentTemplatesSuccess = templates => {
  return dispatch => {
    dispatch({ type: LOAD_DEPARTMENT_TEMPLATES_SUCCESS, templates });
  };
};

export const loadDepartmentTemplatesFailure = error => {
  handleError(error);
  return {
    type: LOAD_DEPARTMENT_TEMPLATES_FAILURE,
    error: error,
  };
};

export const addTemplate = (name, requiredFields, departmentID) => {
  const body = {
    name: name,
    requiredFields: requiredFields,
    departmentId: departmentID,
  };

  return dispatch => {
    dispatch({ type: ADD_TEMPLATE });
    axios
      .post(`${baseUrl}api/contract-templates`, body)
      .then(response => {
        dispatch(addTemplateSuccess(response.data));
      })
      .catch(error => dispatch(addTemplateFailure(error)));
  };
};

export const addTemplateSuccess = template => {
  return dispatch => {
    dispatch({ type: ADD_TEMPLATE_SUCCESS, template });
  };
};

export const addTemplateFailure = error => {
  handleError(error);
  return {
    type: ADD_TEMPLATE_FAILURE,
    error: error,
  };
};

export const editTemplate = (name, requiredFields, templateId) => {
  const body = {
    name: name,
    requiredFields: requiredFields,
  };

  return dispatch => {
    dispatch({ type: EDIT_TEMPLATE });
    axios
      .put(`${baseUrl}api/contract-templates/${templateId}`, body)
      .then(response => {
        dispatch(editTemplateSuccess(name, requiredFields, templateId));
      })
      .catch(error => dispatch(editTemplateFailure(error)));
  };
};

export const editTemplateSuccess = (name, requiredFields, templateId) => {
  return dispatch => {
    dispatch({ type: EDIT_TEMPLATE_SUCCESS, name, requiredFields, templateId });
    dispatch(showSnackbar("Zmiany zostały zapisane"));
  };
};

export const editTemplateFailure = error => {
  handleError(error);
  return {
    type: EDIT_TEMPLATE_FAILURE,
    error: error,
  };
};

export const deleteTemplate = templateId => {
  return dispatch => {
    dispatch({ type: DELETE_TEMPLATE });
    axios
      .delete(`${baseUrl}api/contract-templates/${templateId}`)
      .then(response => {
        dispatch(deleteTemplateSuccess(templateId));
      })
      .catch(error => dispatch(deleteTemplateFailure(error)));
  };
};

export const deleteTemplateSuccess = templateId => {
  return dispatch => {
    dispatch({ type: DELETE_TEMPLATE_SUCCESS, templateId });
  };
};

export const deleteTemplateFailure = error => {
  handleError(error);
  return {
    type: DELETE_TEMPLATE_FAILURE,
    error: error,
  };
};
