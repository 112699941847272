import React, { Component } from "react";
import "./Topbar.scss";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authentication.actions";
class Topbar extends Component {
  render() {
    return (
      <div className="top-bar">
        <div className="logo">
          <img src="/logo.png" alt="logo"></img>
        </div>
        <nav className="navbar">
          <NavLink activeClassName="active" className="navbar-item" to="/documents">
            Umowy
          </NavLink>
          {this.props.user.isAdmin && (
            <>
              <NavLink activeClassName="active" className="navbar-item" to="/users">
                Użytkownicy
              </NavLink>
              <NavLink activeClassName="active" className="navbar-item" to="/departments">
                Działy
              </NavLink>
              <NavLink activeClassName="active" className="navbar-item" to="/templates">
                Szablony
              </NavLink>
              <NavLink activeClassName="active" className="navbar-item" to="/contractors">
                Kontrahenci
              </NavLink>
            </>
          )}
        </nav>
        <div className="notifications">
          <div className="vertical-line"></div>
          <NavLink to="/notifications">
            <SVG className="icon" src="/icons/bell.svg" />
          </NavLink>
          <div className="vertical-line"></div>
        </div>
        <div className="user">
          <p className="username">{this.props.user.firstName + " " + this.props.user.lastName}</p>
          <p className="logout" onClick={this.props.logout}>
            Wyloguj
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Topbar);
