import * as actionTypes from "../actions/newDocument.actions";
import { ADD_DOCUMENT_SUCCESS } from "../actions/agreements.actions";
import cloneDeep from "lodash/cloneDeep";
import { saveAs } from "file-saver";

const initialState = {
  documentId: null,
  attachments: [],
};

const newDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DOCUMENT_SUCCESS: {
      return {
        ...cloneDeep(state),
        documentId: action.document._id,
      };
    }
    case actionTypes.ADD_ATTACHMENT_SUCCESS: {
      let newAttachments = cloneDeep(state.attachments);
      newAttachments.push({
        ...action.attachment,
        description: action.description,
      });

      return {
        ...cloneDeep(state),
        attachments: newAttachments,
      };
    }
    case actionTypes.DELETE_ATTACHMENT_SUCCESS: {
      let newAttachments = cloneDeep(state.attachments);
      const index = newAttachments.findIndex(
        attachment => attachment._id === action.attachmentId,
      );
      newAttachments.splice(index, 1);
      return {
        ...cloneDeep(state),
        attachments: newAttachments,
      };
    }
    case actionTypes.GET_ATTACHMENT_SUCCESS: {
      var blob = new Blob([action.attachment]);
      saveAs(blob, action.name);

      return {
        ...cloneDeep(state),
      };
    }
    case actionTypes.OPEN_ATTACHMENT: {
      var file = new Blob([action.attachment], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return {
        ...cloneDeep(state),
      };
    }
    case actionTypes.CLEAR_NEW_DOCUMENT: {
      return {
        documentId: null,
        attachments: [],
      };
    }
    default:
      return state;
  }
};

export default newDocumentReducer;
