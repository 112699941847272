import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { withStyles } from "@material-ui/core/styles";

export const StyledTableHead = withStyles(theme => ({
  root: {
    backgroundColor: "#EDEEF2",
  },
}))(TableHead);

export const StyledTableContainer = withStyles(theme => ({
  root: {
    boxShadow: "3px 3px 25px 0px rgba(3,0,75,0.03)",
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    padding: "20px 20px 0 20px",
  },
}))(TableContainer);

export const ClearTableContainer = withStyles(theme => ({
  root: {
    boxShadow: "none",
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    padding: "0",
  },
}))(TableContainer);

export const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: 0,
    fontFamily: "unset",
  },
  head: {
    fontWeight: 700,
    fontFamily: "unset",
  },
}))(TableCell);

export const SimpleTableContainer = withStyles(theme => ({
  root: {
    boxShadow: "none",
    backgroundColor: theme.palette.common.white,
    width: "unset",
    padding: "0",
  },
}))(TableContainer);

export const SimpleTableCell = withStyles(theme => ({
  root: {
    borderBottom: 0,
    fontFamily: "unset",
  },
  head: {
    fontFamily: "unset",
    fontSize: "0.8rem",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "400",
  },
  sizeSmall: {
    padding: "6px 34px 6px 0px",
  },
}))(TableCell);

export const SimpleTableHead = withStyles(theme => ({
  root: {
    backgroundColor: "white",
  },
}))(TableHead);
