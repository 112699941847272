import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { loadDepartments } from "../../store/actions/departments.actions";
import IconButton from "../../components/ui/IconButton/IconButton";
import {
  loadDepartmentTemplates,
  addTemplate,
  editTemplate,
  deleteTemplate,
} from "../../store/actions/templates.actions";
import AddTemplateModal from "../../components/Templates/AddTemplateModal/AddTemplateModal";
import TemplateDetails from "../../components/Templates/TemplateDetails/TemplateDetails";

const TemplatesPage = () => {
  const dispatch = useDispatch();

  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [addTemplateModalOpen, setAddTemplateModalOpen] = useState(false);

  const { departments, templates } = useSelector(state => ({
    departments: state.departments.departments,
    templates: state.templates.templates,
  }));

  useEffect(() => {
    if (!departments) {
      dispatch(loadDepartments());
    }
  }, [dispatch, departments]);

  const selectDepartment = id => {
    setSelectedDepartmentId(id);
    setSelectedTemplate(null);
    dispatch(loadDepartmentTemplates(id));
  };

  const selectTemplate = template => {
    setSelectedTemplate(template);
  };

  const handleAddTemplate = (name, requiredFields) => {
    dispatch(addTemplate(name, requiredFields, selectedDepartmentId));
    setAddTemplateModalOpen(false);
  };

  const handleEditTemplate = (name, requiredFields) => {
    dispatch(editTemplate(name, requiredFields, selectedTemplate._id));
  };

  const handleDelete = () => {
    dispatch(deleteTemplate(selectedTemplate._id));
    setSelectedTemplate(null);
  };

  return (
    <div className="departments-page">
      <div className="departments-menu panel shrink-0">
        <div className="panel-title">
          <span>Działy</span>
        </div>
        <List component="nav" dense aria-label="secondary mailbox folders">
          {departments &&
            departments.map((row, id) => (
              <ListItem
                key={id}
                button
                onClick={() => selectDepartment(row._id)}
              >
                <ListItemText
                  primary={row.name}
                  className={
                    row._id === selectedDepartmentId ? "list-item-active" : ""
                  }
                />
              </ListItem>
            ))}
        </List>
      </div>

      {selectedDepartmentId && (
        <div className="departments-menu panel shrink-0">
          <div className="panel-title">
            <span>Szablony</span>
            <IconButton
              onClick={() => setAddTemplateModalOpen(true)}
              id="add-icon"
              icon="plus"
              className="icon-button primary"
            ></IconButton>
          </div>
          <List component="nav" dense aria-label="secondary mailbox folders">
            {templates &&
              templates.map((row, id) => (
                <ListItem key={id} button onClick={() => selectTemplate(row)}>
                  <ListItemText
                    primary={row.name}
                    className={
                      selectedTemplate
                        ? row._id === selectedTemplate._id
                          ? "list-item-active"
                          : ""
                        : ""
                    }
                  />
                </ListItem>
              ))}
          </List>
        </div>
      )}

      {selectedTemplate && (
        <div className="panel">
          <div className="panel-title">
            <span>Szablon - {selectedTemplate.name}</span>
            <IconButton
              onClick={handleDelete}
              icon="trash"
              className="icon-button primary"
            ></IconButton>
          </div>
          <div className="p-4">
            <TemplateDetails
              name={selectedTemplate.name}
              fieldNames={selectedTemplate.requiredFields}
              btnLabel={"Zapisz"}
              handleClick={handleEditTemplate}
            ></TemplateDetails>
          </div>
        </div>
      )}

      <AddTemplateModal
        open={addTemplateModalOpen}
        close={() => setAddTemplateModalOpen(false)}
        addTemplate={handleAddTemplate}
      ></AddTemplateModal>
    </div>
  );
};

export default TemplatesPage;
