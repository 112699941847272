import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadAgreement,
  editAgreement,
} from "../../store/actions/agreements.actions";
import Button from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import { history } from "../../helpers/history";
import AddUserToListModal from "../../components/Departments/AddUserToListModal";
import {
  selectKeeper,
  setUsers,
  deleteUser,
  selectContractor,
  addUser,
} from "../../store/actions/newAgreement.actions";
import SelectContractorDialog from "../../components/SelectContractorDialog/SelectContractorDialog";

const EditAgreementPage = props => {
  const dispatch = useDispatch();
  const { agreement, newAgreement } = useSelector(state => ({
    agreement: state.agreement.agreement,
    newAgreement: state.newAgreement,
  }));

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [selectKeeperModalOpen, setSelectKeeperModalOpen] = useState(false);
  const [selectContractorModalOpen, setSelectContractorModalOpen] = useState(
    false,
  );

  useEffect(() => {
    const {
      match: { params },
    } = props;
    if (!agreement || agreement._id !== params.agreementId) {
      dispatch(loadAgreement(params.agreementId));
    }
    if (agreement) {
      const keeper = agreement.assignedUsers.find(user => user.isKeeper);
      if (keeper) {
        dispatch(
          selectKeeper(
            agreement.assignedUsers.find(user => user.isKeeper).user,
          ),
        );
      }
      dispatch(selectContractor(agreement.contractor));
      dispatch(setUsers(agreement.assignedUsers.map(user => user.user)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement]);

  const handleEdit = () => {
    let assignedUsers = newAgreement.users.map(user => ({
      isKeeper: false,
      user: user._id,
    }));

    const index = assignedUsers.findIndex(
      user => user.user === newAgreement.keeper._id,
    );

    if (index !== -1) {
      assignedUsers[index].isKeeper = true;
    } else {
      assignedUsers.push({ isKeeper: true, user: newAgreement.keeper._id });
    }

    dispatch(
      editAgreement(agreement._id, newAgreement.contractor._id, assignedUsers),
    );
    history.push(`/documents/${agreement._id}`);
  };

  const handleAddUser = user => {
    dispatch(addUser(user));
    setAddUserModalOpen(false);
  };

  const handleSelectKeeper = user => {
    dispatch(selectKeeper(user));
    setSelectKeeperModalOpen(false);
  };

  const hadnleSelectContractor = contractor => {
    dispatch(selectContractor(contractor));
    setSelectContractorModalOpen(false);
  };

  return (
    <div className="panel flex-column">
      <h3>Edytuj dane</h3>
      <p className="font-s bold mb-3">Strona umowy</p>
      <div className="mb-5">
        <div className="flex-row a-center">
          {newAgreement.contractor && (
            <p className="mr-2">{newAgreement.contractor.name}</p>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectContractorModalOpen(true)}
          >
            {newAgreement.contractor
              ? "Zmień kontrahenta"
              : "Wybierz kontrahenta"}
          </Button>
        </div>
      </div>

      <div className="mb-5">
        {newAgreement.users && newAgreement.keeper && (
          <>
            <div className="mb-5">
              <p className="font-s bold mb-3">Opiekun dokumentu</p>
              <div className="flex-row a-center mb-3">
                {newAgreement.keeper && (
                  <p className="mr-2">
                    {newAgreement.keeper.firstName +
                      " " +
                      newAgreement.keeper.lastName}
                  </p>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectKeeperModalOpen(true)}
                >
                  {newAgreement.keeper ? "Zmień opiekuna" : "Wybierz opiekuna"}
                </Button>
              </div>
            </div>
            <div>
              <p className="font-s bold mb-3">
                Użytkownicy, którzy będą mieć dostęp do dokumentu
              </p>
              <div className="flex-row flex-wrap mt-3">
                {newAgreement.users.map(user => (
                  <div key={user._id} className="badge">
                    {user.firstName + " " + user.lastName}
                    <SVG
                      onClick={() => dispatch(deleteUser(user._id))}
                      className="icon btn-close"
                      style={{
                        fill: "white",
                        height: "0.6rem",
                        cursos: "pointer",
                      }}
                      src={`/icons/close.svg`}
                    ></SVG>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <Button
          className="mt-3"
          variant="contained"
          color="primary"
          onClick={() => setAddUserModalOpen(true)}
        >
          Dodaj pracownika
        </Button>
        <AddUserToListModal
          headerText="Wybierz użytkownika, który ma mieć dostęp do dokumentu"
          open={addUserModalOpen}
          close={() => setAddUserModalOpen(false)}
          addUser={handleAddUser}
          usersOnListIds={
            newAgreement.users ? newAgreement.users.map(user => user._id) : []
          }
        ></AddUserToListModal>
        <AddUserToListModal
          headerText="Wybierz opiekuna"
          open={selectKeeperModalOpen}
          close={() => setSelectKeeperModalOpen(false)}
          addUser={handleSelectKeeper}
          usersOnListIds={[]}
        ></AddUserToListModal>
        <SelectContractorDialog
          headerText="Wybierz kontrahenta"
          open={selectContractorModalOpen}
          close={() => setSelectContractorModalOpen(false)}
          selectContractor={hadnleSelectContractor}
        ></SelectContractorDialog>
      </div>

      <Button
        variant="contained"
        className="mb-4 ml-auto"
        color="primary"
        onClick={() => handleEdit()}
      >
        Zapisz
      </Button>
    </div>
  );
};

export default EditAgreementPage;
