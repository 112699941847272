import { handleError } from "../../helpers/handleError";
import axios from "axios";

export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const ADD_ATTACHMENT_SUCCESS = "ADD_ATTACHMENT_SUCCESS";
export const ADD_ATTACHMENT_FAILURE = "ADD_ATTACHMENT_FAILURE";

export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_FAILURE = "DELETE_ATTACHMENT_FAILURE";

export const GET_ATTACHMENT = "GET_ATTACHMENT";
export const GET_ATTACHMENT_SUCCESS = "GET_ATTACHMENT_SUCCESS";
export const GET_ATTACHMENT_FAILURE = "GET_ATTACHMENT_FAILURE";

export const EDIT_ATTACHMENT = "EDIT_ATTACHMENT";
export const EDIT_ATTACHMENT_SUCCESS = "EDIT_ATTACHMENT_SUCCESS";
export const EDIT_ATTACHMENT_FAILURE = "EDIT_ATTACHMENT_FAILURE";

export const OPEN_ATTACHMENT = "OPEN_ATTACHMENT";
export const CLEAR_NEW_DOCUMENT = "CLEAR_NEW_DOCUMENT";

const baseUrl = process.env.REACT_APP_API_URL;

export const addAttachment = (documentId, file, description) => {
  return dispatch => {
    dispatch({ type: ADD_ATTACHMENT });
    axios
      .post(`${baseUrl}api/documents/${documentId}/attachments`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        dispatch(addAttachmentSuccess(response.data, documentId, description));
      })
      .catch(error => dispatch(addAttachmentFailure(error)));
  };
};

export const addAttachmentSuccess = (attachment, documentId, description) => {
  return dispatch => {
    dispatch({
      type: ADD_ATTACHMENT_SUCCESS,
      attachment,
      description,
    });
    dispatch(editAttachment(documentId, attachment._id, description));
  };
};

export const addAttachmentFailure = error => {
  handleError(error);
  return {
    type: ADD_ATTACHMENT_FAILURE,
    error: error,
  };
};

export const editAttachment = (documentId, attachmentId, description) => {
  return dispatch => {
    dispatch({ type: EDIT_ATTACHMENT });
    axios
      .put(
        `${baseUrl}api/documents/${documentId}/attachments/${attachmentId}`,
        { description },
      )
      .then(() => {
        dispatch(editAttachmentSuccess());
      })
      .catch(error => dispatch(editAttachmentFailure(error)));
  };
};

export const editAttachmentSuccess = () => {
  return {
    type: EDIT_ATTACHMENT_SUCCESS,
  };
};

export const editAttachmentFailure = error => {
  handleError(error);
  return {
    type: EDIT_ATTACHMENT_FAILURE,
    error: error,
  };
};

export const getAttachment = (
  documentId,
  attachmentId,
  name,
  download = false,
) => {
  return dispatch => {
    dispatch({ type: GET_ATTACHMENT });
    axios
      .get(
        `${baseUrl}api/documents/${documentId}/attachments/${attachmentId}`,
        {
          responseType: "arraybuffer",
        },
      )
      .then(response => {
        download
          ? dispatch(getAttachmentSuccess(response.data, name))
          : dispatch(openAttachment(response.data, name));
      })
      .catch(error => dispatch(getAttachmentFailure(error)));
  };
};

export const getAttachmentSuccess = (attachment, name) => {
  return {
    type: GET_ATTACHMENT_SUCCESS,
    attachment,
    name,
  };
};

export const openAttachment = (attachment, name) => {
  return {
    type: OPEN_ATTACHMENT,
    attachment,
    name,
  };
};

export const getAttachmentFailure = error => {
  handleError(error);
  return {
    type: GET_ATTACHMENT_FAILURE,
    error: error,
  };
};

export const deleteAttachment = (documentId, attachmentId) => {
  return dispatch => {
    dispatch({ type: DELETE_ATTACHMENT });
    axios
      .delete(
        `${baseUrl}api/documents/${documentId}/attachments/${attachmentId}`,
      )
      .then(() => {
        dispatch(deleteAttachmentSuccess(attachmentId));
      })
      .catch(error => dispatch(deleteAttachmentFailure(error)));
  };
};

export const deleteAttachmentSuccess = attachmentId => {
  return {
    type: DELETE_ATTACHMENT_SUCCESS,
    attachmentId,
  };
};

export const deleteAttachmentFailure = error => {
  handleError(error);
  return {
    type: DELETE_ATTACHMENT_FAILURE,
    error: error,
  };
};

export const clearNewDocument = () => {
  return {
    type: CLEAR_NEW_DOCUMENT,
  };
};
