import React, { useState } from "react";
import CustomInput, { StyledInput } from "../../ui/CustomInput/CustomInput";
import CustomCheckbox from "../../ui/CustomCheckbox/CustomCheckbox";
import { templateFields } from "../../../helpers/template-fields-list";
import Button from "@material-ui/core/Button";
import "./TemplateDetails.scss";

const TemplateDetails = props => {
  const [name, setName] = useState(props.name);
  const [requiredFields, setRequiredFields] = useState(
    templateFields
      .filter(field => !field.required)
      .map(field => ({
        id: field.id,
        label: field.label,
        checked: props.fieldNames.includes(field.id),
      })),
  );

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleChange = (event, id) => {
    let newFields = requiredFields.map(field => ({ ...field }));
    newFields[id].checked = event.target.checked;
    setRequiredFields(newFields);
  };

  React.useEffect(() => {
    setName(props.name);
    setRequiredFields(
      templateFields
        .filter(field => !field.required)
        .map(field => ({
          id: field.id,
          label: field.label,
          checked: props.fieldNames.includes(field.id),
        })),
    );
  }, [props.name, props.fieldNames]);

  return (
    <div className="flex-column">
      <div className="mb-3">
        <CustomInput label="Nazwa szablonu">
          <StyledInput value={name} onChange={handleNameChange} />
        </CustomInput>
      </div>
      <p className="caption mb-3 mt-4">Pola wymagane</p>
      <div className="template-checkbox-list mb-3">
        {requiredFields.map((field, id) => (
          <CustomCheckbox
            className="checkbox"
            key={id}
            checked={field.checked}
            label={field.label}
            onChange={event => handleChange(event, id)}
          ></CustomCheckbox>
        ))}
      </div>
      <Button
        onClick={() =>
          props.handleClick(
            name,
            [
              ...templateFields.filter(field => field.required),
              ...requiredFields.filter(field => field.checked),
            ].map(field => field.id),
          )
        }
        variant="contained"
        color="primary"
        className="ml-auto"
        size="large"
      >
        {props.btnLabel}
      </Button>
    </div>
  );
};

export default TemplateDetails;
