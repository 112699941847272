import * as actionTypes from "../actions/authentication.actions";
const baseUrl = process.env.REACT_APP_API_URL;

let accessToken = localStorage.getItem("accessToken");

const initialState = {
  partnerInfo: null,
  token: accessToken,
  loggedIn: !!accessToken,
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARTNER_INFO_SUCCESS: {
      localStorage.setItem("partnerId", action.info._id);

      window.location.replace(`${baseUrl}api/auth?partnerId=${action.info._id}`);

      return {
        ...state,
        partnerInfo: action.info,
      };
    }
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        token: action.token,
        user: action.user,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        token: null,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
