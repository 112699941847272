export const SNACKBAR_OPEN = "SNACKBAR_OPEN";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";

export const showSnackbar = message => {
  return dispatch => {
    dispatch({ type: SNACKBAR_OPEN, message });
  };
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: SNACKBAR_CLEAR });
  };
};
