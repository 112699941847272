import * as actionTypes from "../actions/notifications.actions";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  loading: false,
  notifications: [],
  count: null,
  page: 0,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS: {
      return {
        ...cloneDeep(state),
        loading: true,
      };
    }
    case actionTypes.GET_NOTIFICATIONS_SUCCESS: {
      const newNotifications = cloneDeep(state.notifications);
      newNotifications.push(...action.notifications.data);

      return {
        ...cloneDeep(state),
        count: action.notifications.count,
        notifications: newNotifications,
        loading: false,
      };
    }
    case actionTypes.MARK_AS_READ_SUCCESS: {
      const newNotifications = cloneDeep(state.notifications);
      const index = newNotifications.findIndex(notification => notification._id === action.id);
      if (index !== -1) {
        newNotifications[index].seen = true;
      }

      return {
        ...cloneDeep(state),
        notifications: newNotifications,
      };
    }
    case actionTypes.MARK_ALL_AS_READ_SUCCESS: {
      const newNotifications = cloneDeep(state.notifications).map(notification => ({ ...notification, seen: true }));

      return {
        ...cloneDeep(state),
        notifications: newNotifications,
      };
    }
    case actionTypes.CHANGE_NOTIFICATIONS_PAGE: {
      return {
        ...cloneDeep(state),
        page: action.page,
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
