import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormHelperText from "@material-ui/core/FormHelperText";

export const StyledInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    padding: "10px 26px 10px 12px",
    backgroundColor: props => (props.theme === "dark" ? "#D9DCE8" : "#EDEEF2"),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: props =>
        props.theme === "dark" ? "#D1D5E5" : "#E8EAEF",
    },
  },
}))(InputBase);

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 195,
    borderRadius: "4px",
    border: "0",
  },
}));

export const CustomInputLabel = withStyles(theme => ({
  root: {
    fontFamily: "unset",
  },
}))(InputLabel);

const CustomInput = props => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl + " " + props.className}>
      <CustomInputLabel error={props.error} shrink={true}>
        {props.label}
        {props.star && <span style={{ color: "red" }}> *</span>}
      </CustomInputLabel>

      {props.children}
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomInput;
