import { handleError } from "../../helpers/handleError";
import axios from "axios";
import { history } from "../../helpers/history";

export const GET_PARTNER_INFO = "GET_PARTNER_INFO";
export const GET_PARTNER_INFO_SUCCESS = "GET_PARTNER_INFO_SUCCESS";
export const GET_PARTNER_INFO_FAILURE = "GET_PARTNER_INFO_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

const baseUrl = process.env.REACT_APP_API_URL;

export const getPartnerInfo = domain => {
  const domainName =
    domain === "localhost" ? "web.devel.autenti.nextapps.pl" : domain;
  return dispatch => {
    dispatch({ type: GET_PARTNER_INFO });
    axios
      .get(`${baseUrl}api/partners/${domainName}/info`)
      .then(response => {
        dispatch(getPartnerInfoSuccess(response.data));
      })
      .catch(error => dispatch(getPartnerInfoFailure(error)));
  };
};

export const getPartnerInfoSuccess = info => {
  return {
    type: GET_PARTNER_INFO_SUCCESS,
    info,
  };
};

export const getPartnerInfoFailure = error => {
  handleError(error);
  return { type: GET_PARTNER_INFO_FAILURE };
};

export const login = token => {
  localStorage.setItem("accessToken", token);

  return dispatch => {
    dispatch({ type: LOGIN });
    axios
      .get(`${baseUrl}api/users/me`)
      .then(response => {
        dispatch(loginSuccess(response.data, token));
      })
      .catch(error => dispatch(loginFailure(error)));
  };
};

export const loginSuccess = (user, token) => {
  return {
    type: LOGIN_SUCCESS,
    user,
    token,
  };
};

export const loginFailure = error => {
  handleError(error);
  return dispatch => {
    dispatch({ type: LOGIN_FAILURE });
    dispatch(logout());
  };
};

export const logout = () => {
  localStorage.removeItem("accessToken");
  history.push("/login");
  return {
    type: LOGOUT,
  };
};
