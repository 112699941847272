import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "../../components/ui/IconButton/IconButton";
import {
  ClearTableContainer,
  StyledTableCell,
  StyledTableHead,
} from "../../helpers/table-styles";


const DepartmentUsersList = (props) => {

  const {users, remove} = props;
  return (
    <ClearTableContainer>
    <Table>
      <StyledTableHead>
        <TableRow>
          <StyledTableCell>ID</StyledTableCell>
          <StyledTableCell>Imię i nazwisko</StyledTableCell>
          <StyledTableCell>E-mail</StyledTableCell>
          <StyledTableCell align="right">Usuń</StyledTableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {users
          .map(row => (
            <TableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                {row._id}
              </StyledTableCell>
              <StyledTableCell>
                {row.firstName + " " + row.lastName}
              </StyledTableCell>
              <StyledTableCell>{row.emailAddress}</StyledTableCell>
              <StyledTableCell align="right">
                <IconButton
                  btnClick={() => remove(row._id)}
                  style={{ marginLeft: "auto" }}
                  icon="trash"
                ></IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </ClearTableContainer>
  )
}

export default DepartmentUsersList;