/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomSelect from "../ui/CustomSelect/CustomSelect";
import { loadDepartments } from "../../store/actions/departments.actions";
import {
  setTemplateId,
  setDepartmentId,
} from "../../store/actions/newAgreement.actions";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";

const TemplateStep = props => {
  const dispatch = useDispatch();
  const { next } = props;

  const { departments, templates, departmentId, templateId } = useSelector(
    state => ({
      departments: state.departments.departments,
      templates: state.templates.templates,
      departmentId: state.newAgreement.departmentId,
      templateId: state.newAgreement.templateId,
    }),
  );

  const [departmentOptions, setDepartmentOptions] = useState(
    departments
      ? departments.map(department => ({
          value: department._id,
          label: department.name,
        }))
      : [],
  );

  const [templateOptions, setTemplateOptions] = useState([]);

  const selectDepartment = event => {
    dispatch(setDepartmentId(event.target.value));
  };

  const selectTemplate = event => {
    dispatch(setTemplateId(event.target.value));
  };

  useEffect(() => {
    if (!departments) {
      dispatch(loadDepartments());
    } else {
      setDepartmentOptions(
        departments.map(department => ({
          value: department._id,
          label: department.name,
        })),
      );

      if (!departmentId) {
        dispatch(setDepartmentId(departments[0]._id));
      }
    }
  }, [departments]);

  useEffect(() => {
    if (templates && templates.length > 0) {
      setTemplateOptions(
        templates.map(template => ({
          value: template._id,
          label: template.name,
        })),
      );
    }
  }, [templates]);

  useEffect(() => {
    if (
      templateOptions.length > 0 &&
      (!templateId ||
        !templateOptions
          .map(templateOption => templateOption.value)
          .includes(templateId))
    ) {
      dispatch(setTemplateId(templates[0]._id));
    }
  }, [templateOptions]);

  return (
    <div className="mt-3">
      {departmentId && (
        <CustomSelect
          label="Dział"
          changeOption={selectDepartment}
          selectedOption={departmentId}
          options={departmentOptions}
        ></CustomSelect>
      )}
      <div className="mt-4">
        {templateId && (
          <CustomSelect
            label="Szablon"
            changeOption={selectTemplate}
            selectedOption={templateId}
            options={templateOptions}
          ></CustomSelect>
        )}
        {templates &&
          templates.length === 0 &&
          departmentOptions.length > 0 && (
            <p className="caption">Brak szablonów w dziale</p>
          )}
      </div>
      <div className="actionsContainer">
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!templateId}
            onClick={next}
          >
            Dalej
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateStep;
