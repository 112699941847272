import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {
  SimpleTableContainer,
  SimpleTableCell,
  SimpleTableHead,
} from "../../../helpers/table-styles";

const SimpleTable = props => {
  const { rows, labels } = props;
  return (
    <React.Fragment>
      <SimpleTableContainer>
        <Table size="small">
          <SimpleTableHead>
            <TableRow>
              {labels.map((label, id) => (
                <SimpleTableCell key={id}>{label}</SimpleTableCell>
              ))}
            </TableRow>
          </SimpleTableHead>
          <TableBody>
            {rows.map((row, rowId) => (
              <TableRow key={rowId}>
                {Object.values(row).map((value, id) => (
                  <SimpleTableCell key={`${rowId}${id}`}>
                    {value}
                  </SimpleTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleTableContainer>
    </React.Fragment>
  );
};

export default SimpleTable;
