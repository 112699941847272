/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "../../components/ui/IconButton/IconButton";
import CustomInput, {
  StyledInput,
} from "../../components/ui/CustomInput/CustomInput";
import {
  StyledTableContainer,
  StyledTableCell,
  StyledTableHead,
} from "../../helpers/table-styles";
import {
  loadContractors,
  changePage,
  setSearchFilter,
} from "../../store/actions/contractors.actions";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";

const SelectContractorDialog = props => {
  const { open, close, selectContractor, headerText, setOpenNew } = props;
  const [rowsPerPage] = useState(5);

  const dispatch = useDispatch();

  const { loading, contractors, count, page, filters } = useSelector(state => ({
    loading: state.contractors.loading,
    contractors: state.contractors.contractors,
    count: state.contractors.count,
    page: state.contractors.page,
    filters: state.contractors.filters,
  }));

  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (!contractors) {
      dispatch(loadContractors(0));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    if (newPage * 5 >= contractors.length) {
      dispatch(loadContractors(newPage, filters));
    } else {
      dispatch(changePage(newPage));
    }
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setSearchString(value);
    if (!loading) {
      dispatch(setSearchFilter(value !== "" ? value : null));
    }
  };


  const handleClose = () => {
    dispatch(setSearchFilter(null), setSearchFilter(""));
    close();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal modal-m">
        <div className="modal-title">
          <p>{headerText}</p>
        </div>
        <div className="modal-content">
          <div className="table-contaier">
            <StyledTableContainer>
              <div className="flex-row j-between a-end">
                <CustomInput className="mr-4 mb-3" label="Szukaj kontrahenta">
                  <StyledInput
                    name={"name"}
                    value={searchString}
                    onChange={event => handleInputChange(event)}
                  />
                </CustomInput>
                <Button
                  onClick={() => setOpenNew(true)}
                  variant="contained"
                  color="primary"
                  className="mb-4"
                >
                  Dodaj kontrahenta
                </Button>
              </div>
              {count > 0 && (
                <>
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        <StyledTableCell>Nazwa</StyledTableCell>
                        <StyledTableCell>Pełna nazwa</StyledTableCell>
                        <StyledTableCell>NIP</StyledTableCell>
                        <StyledTableCell align="right">Wybierz</StyledTableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {contractors
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map(row => (
                          <TableRow key={row._id}>
                            <StyledTableCell>{row.name}</StyledTableCell>
                            <StyledTableCell>{row.fullName}</StyledTableCell>
                            <StyledTableCell>{row.tin}</StyledTableCell>
                            <StyledTableCell align="right">
                              <IconButton
                                btnClick={() => selectContractor(row)}
                                style={{ marginLeft: "auto" }}
                                icon="plus"
                              ></IconButton>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={count}
                    rowsPerPageOptions={[]}
                    page={page}
                    nextIconButtonProps={{
                      disabled:
                        loading || page + 1 >= Math.ceil(count / rowsPerPage),
                    }}
                    backIconButtonProps={{ disabled: loading || page === 0 }}
                    onChangePage={handleChangePage}
                  />
                </>
              )}
            </StyledTableContainer>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectContractorDialog;
