import React from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const DepartmentModal = props => {
  const { open, close, confirm, title } = props;

  return (
    <Modal open={open} onClose={close}>
      <div className="modal edit-modal">
        <div className="modal-title">
          <p>{title}</p>
        </div>
        <div className="modal-content flex-row j-center">
          <Button onClick={() => confirm()} variant="contained" color="primary">
            Potwierdź
          </Button>
          <Button onClick={() => close()} variant="contained">
            Anuluj
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DepartmentModal;
